import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeleteEquipmentHistories, listProjectors } from '../../../graphql/queries';
import { createDeleteEquipmentHistory, createProjector, deleteProjector, updateProjector } from '../../../graphql/mutations';
import './Proyector.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';

const Proyector = () => {
    const [projector, setProjector] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showProjectorAddOverlay, setShowProjectorAddOverlay] = useState(false);
    const [selectedProjector, setSelectedProjector] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newProjector, setNewProjector] = useState({
        id: '',
        tagId: '',
        brand: '',
        serial_Number: '',
        charger_cable: '',
        output_cable: '',
        price: '',
        store: '',
        purchase_date: '',
        warranty_date: '',
        returnable_date: '',
        available: '',
        status: '',
        location: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');


    const handleSaveAsExcel = async () => {
        try {
            const projectorToExport = filteredProjectors.length > 0 ? filteredProjectors : projector;
            if (projectorToExport.length > 0) {
                // Sort the Projectors by Timestamp in ascending order (earliest first)
                const sortedProjector = [...projectorToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedProjector.map((projector, index) => ({
                    '#': index + 1,
                    'TAG:': projector.tagId,
                    'Available:': projector.available ? 'Yes' : 'No',
                    'Serial Number:': projector.serial_Number,
                    'Status:': projector.status,
                    'Location:': projector.location,
                }));

                // Crea una hoja de cálculo vacía
                const worksheet = XLSX.utils.json_to_sheet([]);

                // Agrega el título en A1
                XLSX.utils.sheet_add_aoa(worksheet, [['Projectors']], { origin: 'A1' });
                let endCol = 0; // Mover la declaración de endCol aquí para que esté en el alcance de toda la función

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } });

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20,
                        color: { rgb: '1F4E79' }
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is row 2 (A2)
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' }
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' }
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' }
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Width for Row Number
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 }
                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Projectors');

                // Write file
                XLSX.writeFile(workbook, 'ProjectorsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditProjector = (scanner) => {
        setSelectedProjector(scanner);
        setIsEditing(true);
        setShowProjectorAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listProjectors, {
        variables: { limit: 100 },
    });

    const [addProjector] = useMutation(createProjector, {
        refetchQueries: [{ query: listProjectors, variables: { limit: 100 } }]
    });

    const [removeProjector] = useMutation(deleteProjector, {
        refetchQueries: [{ query: listProjectors, variables: { limit: 100 } }]
    });
    const [updProjector] = useMutation(updateProjector, {
        refetchQueries: [{ query: listProjectors, variables: { limit: 100 } }]
    });
    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    const [fetchAllProjectors, { loading: countLoading, data: AllProjectorsData }] = useLazyQuery(listProjectors, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllProjectors = async () => {
            let AllProjectors = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllProjectors = [...AllProjectors, ...data.listProjectors.items];
                currentNextToken = data.listProjectors.nextToken;
            } while (currentNextToken);

            setProjector(AllProjectors);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllProjectors();
        }
    }, [data, fetchMore]);

    const clearNewProjector = () => {
        setNewProjector({
            id: '',
            tagId: '',
            brand: '',
            serial_Number: '',
            charger_cable: '',
            output_cable: '',
            price: '',
            store: '',
            purchase_date: '',
            warranty_date: '',
            returnable_date: '',
            available: '',
            status: '',
            location: '',

        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listProjectors.nextToken);
                return {
                    listProjectors: {
                        ...fetchMoreResult.listProjectors,
                        items: [...prevResult.listProjectors.items, ...fetchMoreResult.listProjectors.items]
                    }
                };
            }
        });
    };

    const filteredProjectors = useFilteredData(projector || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        if (isEditing) {
            setSelectedProjector({
                ...selectedProjector,
                [name]: formattedValue
            });
        } else {
            setNewProjector({
                ...newProjector,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddProjector = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newProjector.purchase_date
            ? new Date(newProjector.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedWarranty = newProjector.warranty_date
            ? new Date(newProjector.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newProjector.returnable_date
            ? new Date(newProjector.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newProjector.price !== "" && newProjector.price !== null && newProjector.price != undefined) {
            priceValue = parseFloat(newProjector.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagId` and `serial_Number`
        const isDuplicate = projector.some(projector =>
            projector.tagId === newProjector.tagId &&
            projector.serial_Number === newProjector.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A projector with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        // Check availability first
        if (newProjector.available === 'true') {

            // If available, status must be Inventario
            if (newProjector.status !== 'inv') {
                setStatusError('If the Projector is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
        } else if (newProjector.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newProjector.status)) {
                setStatusError('If the Projector is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        // If all validations pass, proceed with adding the projector to the database
        addProjector({
            variables: { input: { ...newProjector, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable } }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding projector:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeleteProjector = async () => {
        try {
            // Remover campos innecesarios
            const ProjectorData = removeUnnecessaryFields({ ...selectedProjector }, [

                'charger_cable',
                'output_cable',
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewProjector = Object.fromEntries(
                Object.entries(ProjectorData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewProjector);

            // Normalizar claves alternativas
            cleanedNewProjector.tagId = cleanedNewProjector.tagId || cleanedNewProjector.tagid;
            delete cleanedNewProjector.tagid;

            cleanedNewProjector.previous_employees =
                cleanedNewProjector.previous_employees || cleanedNewProjector.assigned_to;
            delete cleanedNewProjector.assigned_to;

            console.log("Despues de normalizar:", cleanedNewProjector);

            // Añadir valores clave predeterminados
            if (!cleanedNewProjector.Item) {
                cleanedNewProjector.Item = "Projector"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewProjector).forEach((key) => {
                if (cleanedNewProjector[key] === "" || cleanedNewProjector[key] === null) {
                    delete cleanedNewProjector[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewProjector },
            });

            // Eliminar el Projector
            await removeProjector({
                variables: { input: { id: cleanedNewProjector.id } },
            });

            // Resetear estados
            setShowProjectorAddOverlay(false);
            setIsEditing(false);
            setSelectedProjector(null);

        } catch (error) {
            console.error("Error deleting Projector", error);
        }
    };

    const handleUpdateProjector = async () => {

        const formattedData_purchase = selectedProjector.purchase_date ? new Date(selectedProjector.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedProjector.warranty_date ? new Date(selectedProjector.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedProjector.returnable_date ? new Date(selectedProjector.returnable_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedProjector.price !== "" && selectedProjector.price !== null && selectedProjector.price != undefined) {
            priceValue = parseFloat(selectedProjector.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');
        // Check availability first
        if (selectedProjector.available === true) {

            // If available, status must be Inventario
            if (selectedProjector.status !== 'inv') {
                setStatusError('If the Projector is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }

        } else if (selectedProjector.available === false) {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedProjector.status)) {
                setStatusError('If the Projector is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }


        updProjector({
            variables: {
                input: {
                    id: selectedProjector.id,
                    tagId: selectedProjector.tagId,
                    brand: selectedProjector.brand,
                    serial_Number: selectedProjector.serial_Number,
                    charger_cable: selectedProjector.charger_cable,
                    output_cable: selectedProjector.output_cable,
                    price: priceValue,
                    store: selectedProjector.store,
                    purchase_date: formattedData_purchase,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedProjector.available,
                    status: selectedProjector.status,
                    location: selectedProjector.location,


                }
            }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error updating Projector:", error);
        });
    };



    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    return (
        <div className="projector-page">
            <header className="inventory-header">
                <h1>Projector</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>


            </div>
            <div className="searchBar">
                <button className="add-projector-btn" onClick={() => {
                    clearNewProjector();
                    setSelectedProjector(null);
                    setIsEditing(false);
                    setShowProjectorAddOverlay(true);
                }}>+ Add New Projector</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="projector-table-container">
                <table className="projector-table">
                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagId')}>
                                TAG
                                {sortConfig?.key === 'tagId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('output_cable')}>
                                Output Cable
                                {sortConfig?.key === 'output_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>


                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredProjectors.map((projector, index) => (
                            <tr key={projector.id} onClick={() => handleEditProjector(projector)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="projector-tagId">{capitalize(projector.tagId)}</td>
                                <td className="projector-brand">{capitalize(projector.brand.toLowerCase())}</td>
                                <td className="projector-serial_Number">{projector.serial_Number.toLowerCase()}</td>
                                <td className="projector-charger_cable">{projector.charger_cable ? 'Yes' : 'No'} </td>
                                <td className="projector-output_cable">{projector.output_cable ? 'Yes' : 'No'} </td>
                                <td className="projector-price">${formatPrice(projector.price)}</td>
                                <td className="projector-store">{projector.store}</td>
                                <td className="projector-purchase_date">{projector.purchase_date ? formatDate(projector.purchase_date) : 'No Date'}</td>
                                <td className="projector-warranty_date">{projector.warranty_date ? formatDate(projector.warranty_date) : 'No Date'}</td>
                                <td className="projector-returnable_date">{projector.returnable_date ? formatDate(projector.returnable_date) : 'No Date'}</td>
                                <td className="projector-available">{projector.available ? 'Yes' : 'No'} </td>
                                <td className="projector-status">
                                    {projector.status === 'broken' ? 'Broken' :
                                        projector.status === 'use' ? 'Use' :
                                            projector.status === 'transfer' ? 'Transfer' :
                                                projector.status === 'lost' ? 'Lost' :
                                                    projector.status === 'inv' ? 'Inventario' :
                                                        ''} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="projector-location">{projector.location}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showProjectorAddOverlay && (

                <div className="projector_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("projector_add_overlay")) {
                        setShowProjectorAddOverlay(false);
                        clearNewProjector();
                        setSelectedProjector(null);
                        setIsEditing(false);
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Projector' : 'Add New Projector'}</h2>
                            <button onClick={() => {
                                setShowProjectorAddOverlay(false);
                                clearNewProjector();
                                setSelectedProjector(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagId" placeholder="TAG" value={isEditing ? selectedProjector.tagId : newProjector.tagId} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedProjector.brand : newProjector.brand} onChange={handleInputChange} required />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedProjector.serial_Number : newProjector.serial_Number} onChange={handleInputChange} required />
                        <label>Charger Cable:</label>

                        <select name="charger_cable" placeholder="Charger Cable" value={isEditing ? selectedProjector.charger_cable : newProjector.charger_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Output Cable:</label>

                        <select name="output_cable" placeholder="Output Cable" value={isEditing ? selectedProjector.output_cable : newProjector.output_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Price:</label><input name="price" placeholder="Price" value={isEditing ? selectedProjector.price : newProjector.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="Store" value={isEditing ? selectedProjector.store : newProjector.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedProjector.purchase_date : newProjector.purchase_date}
                            onChange={handleInputChange}

                        />

                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedProjector.warranty_date : newProjector.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedProjector.returnable_date : newProjector.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="Available" value={isEditing ? selectedProjector.available : newProjector.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="Status" value={isEditing ? selectedProjector.status : newProjector.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>

                        </select>

                        <label>Location:</label><input name="location" placeholder="Location" value={isEditing ? selectedProjector.location : newProjector.location} onChange={handleInputChange} />


                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-projector" onClick={isEditing ? handleUpdateProjector : handleAddProjector} >
                                {isEditing ? 'Update Projector' : 'Add Projector'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-projector"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this projector?')) {
                                            handleDeleteProjector();
                                        }
                                    }}
                                >
                                    Delete Projector
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );























};

export default Proyector;

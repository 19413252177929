import { useMemo } from 'react';

function useFilteredData(data, searchTerm, sortConfig = null, searchField = null) {
  return useMemo(() => {
    if (!Array.isArray(data)) {
      console.error('Los datos proporcionados no son un arreglo:', data);
      return [];
    }

    let filteredData = data;

    if (searchTerm && searchTerm.trim()) {
      const lowercasedQuery = searchTerm.toLowerCase();
      filteredData = data.filter((item) => {
        let fieldValue;
     
        
        if (searchField && item.hasOwnProperty(searchField)) {
          // Usa solo el campo especificado si `searchField` no es nulo
          fieldValue = item[searchField];
        } else {
          // Concatena todos los valores del objeto si `searchField` es nulo
          fieldValue = Object.values(item)
            .map((value) => {
              // Convierte a cadena y maneja booleanos
              if (value === true) return 'yes';
              if (value === false) return 'no';
              if (value === "inv") return "inventario"; // Reemplazar "inv" con "inventario"
              return value != null ? value.toString().toLowerCase() : ''; // Asegurar valores válidos
            })
            .join(' ');
        }
      
        // Convierte el resultado concatenado a minúsculas y aplica el filtro
        console.log("Objeto original:", item);
        console.log("Valor concatenado:", fieldValue);
        console.log("Resultado del filtro:", fieldValue.includes(lowercasedQuery));
        
        return fieldValue.includes(lowercasedQuery);

      });
         
   
    }

    if (sortConfig !== null) {
      filteredData = [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, searchTerm, sortConfig, searchField]);
}

export default useFilteredData;


import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeleteEquipmentHistories, listUsers, listDeletedUserHistories } from '../../../graphql/queries';
import { createDeleteEquipmentHistory, deleteDeleteEquipmentHistory, updateDeleteEquipmentHistory } from '../../../graphql/mutations';
import './Equipment_Removed.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import Inventory_header from '../../../components/layout/Inventory_header';
import { capitalize } from '../../../components/common/capitalize';

const Equipment_Removed = () => {
    const [equipment_Removed, setEquipment_Removed] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showEquipment_RemovedAddOverlay, setShowEquipment_RemovedAddOverlay] = useState(false);
    const [selectedEquipment_Removed, setSelectedEquipment_Removed] = useState(null);
    const [isEditing, setIsEditing] = useState(false)
    const [newEquipment_Removed, setNewEquipment_Removed] = useState({
        id: '',
        Item: '',
        tagId: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        available: '',
        previous_employees: '',
        location: '',
        notes: '',

    });

    const [sortConfig, setSortConfig] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const { loading1, error: userError1, data: userPreviousEmployee } = useQuery(listDeletedUserHistories);
    const { loading2, error: userError2, data: users } = useQuery(listUsers);


    const handleSaveAsExcel = async () => {
        try {
            const equipment_RemovedToExport = filteredEquipment_Removeds.length > 0 ? filteredEquipment_Removeds : equipment_Removed;

            if (equipment_RemovedToExport.length > 0) {
                const sortedequipment_Removed = [...equipment_RemovedToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                const worksheetData = sortedequipment_Removed.map((equipment_Removed, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'Item': equipment_Removed.Item,
                    'TAG': equipment_Removed.tagId,
                    'Available': equipment_Removed.available ? 'Yes' : 'No',
                    'Previous Employees': equipment_Removed.previous_employees,
                    'Notes': equipment_Removed.notes,
                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Equipment_Removeds']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }); // A1 to G1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Width for Row Number
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 30 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 15 }
                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'equipment_Removeds');

                // Export the file
                XLSX.writeFile(workbook, 'equipment_RemovedsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditEquipment_Removed = (equipment_Removed) => {
        setSelectedEquipment_Removed(equipment_Removed);
        setIsEditing(true);
        setShowEquipment_RemovedAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listDeleteEquipmentHistories, {
        variables: { limit: 100 },
    });


    const [addEquipment_Removed] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    const [removeEquipment_Removed] = useMutation(deleteDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });
    const [updEquipment_Removed] = useMutation(updateDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    useEffect(() => {
        const fetchAllEquipment_Removeds = async () => {
            let AllEquipment_Removeds = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllEquipment_Removeds = [...AllEquipment_Removeds, ...data.listDeleteEquipmentHistories.items];
                currentNextToken = data.listDeleteEquipmentHistories.nextToken;
            } while (currentNextToken);

            setEquipment_Removed(AllEquipment_Removeds);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllEquipment_Removeds();
        }
    }, [data, fetchMore]);

    const clearNewEquipment_Removed = () => {
        setNewEquipment_Removed({
            id: '',
            Item: '',
            tagId: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            available: '',
            previous_employees: '',
            location: '',
            notes: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listEquipment_Removeds.nextToken);
                return {
                    listEquipment_Removeds: {
                        ...fetchMoreResult.listEquipment_Removeds,
                        items: [...prevResult.listEquipment_Removeds.items, ...fetchMoreResult.listEquipment_Removeds.items]
                    }
                };
            }
        });
    };
    const filteredEquipment_Removeds = useFilteredData(equipment_Removed || [], searchTerm, sortConfig);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (isEditing) {
            setSelectedEquipment_Removed({
                ...selectedEquipment_Removed,
                [name]: value
            });
        } else {
            setNewEquipment_Removed({
                ...newEquipment_Removed,
                id: uuidv4(),
                [name]: value
            });
        }
    };

    const handleAddEquipment_Removed = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newEquipment_Removed.purchase_date
            ? new Date(newEquipment_Removed.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newEquipment_Removed.assignment_date
            ? new Date(newEquipment_Removed.assignment_date).toISOString().split('T')[0]
            : null;



        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newEquipment_Removed.price !== "" && newEquipment_Removed.price !== null && newEquipment_Removed.price != undefined) {
            priceValue = parseFloat(newEquipment_Removed.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');


        // If all validations pass, proceed with adding the equipment_Removed to the database
        addEquipment_Removed({
            variables: { input: { ...newEquipment_Removed, price: priceValue, purchase_date: formattedPurchase, assignment_date: formattedAssignment } }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding equipment_Removed:", error);
            });
    };

    const handleDeleteEquipment_Removed = () => {

        removeEquipment_Removed({
            variables: { input: { id: selectedEquipment_Removed.id } }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error deleting Equipment_Removed", error);
        });

    };

    const handleUpdateEquipment_Removed = async () => {

        const formattedData_purchase = selectedEquipment_Removed.purchase_date ? new Date(selectedEquipment_Removed.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedEquipment_Removed.assignment_date ? new Date(selectedEquipment_Removed.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedEquipment_Removed.price !== "" && selectedEquipment_Removed.price !== null && selectedEquipment_Removed.price != undefined) {
            priceValue = parseFloat(selectedEquipment_Removed.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        updEquipment_Removed({
            variables: {
                input: {
                    id: selectedEquipment_Removed.id,
                    Item: selectedEquipment_Removed.Item,
                    tagId: selectedEquipment_Removed.tagId,
                    model: selectedEquipment_Removed.model,
                    brand: selectedEquipment_Removed.brand,
                    serial_Number: selectedEquipment_Removed.serial_Number,
                    price: selectedEquipment_Removed.price,
                    store: selectedEquipment_Removed.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    available: selectedEquipment_Removed.available,
                    previous_employees: selectedEquipment_Removed.previous_employees,
                    location: selectedEquipment_Removed.location,
                    notes: selectedEquipment_Removed.notes,


                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowEquipment_RemovedAddOverlay(false);
            setPriceError('');
            setStatusError('');
            setDuplicateError('');


        }).catch(error => {
            console.error("Error updating Equipment_Removed:", error);
        });
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };


    // listPreviousEmployee
    const PreviousEmployees = React.useMemo(() => {
        if (userPreviousEmployee && userPreviousEmployee.listDeletedUserHistories) {
            return userPreviousEmployee.listDeletedUserHistories.items || [];
        }
        return [];
    }, [userPreviousEmployee]);

    // listUsers
    const user = React.useMemo(() => {
        if (users && users.listUsers) {
            return users.listUsers.items || [];
        }
        return [];
    }, [users]);


    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };


    return (
        <div className="equipment_Removed-page">
            <header className="inventory-header">
                <h1>All Equipment Removed</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>
            </div>

            <div className="searchBar">
                <button className="add-equipment_Removed-btn" onClick={() => {
                    clearNewEquipment_Removed();
                    setSelectedEquipment_Removed(null);
                    setIsEditing(false);
                    setShowEquipment_RemovedAddOverlay(true);
                }}>+ Add New EquipmentRemoved</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="equipment_Removed-table-container">
                <table className="equipment_Removed-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('Item')}>
                                Item
                                {sortConfig?.key === 'Item' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('tagId')}>
                                TAG
                                {sortConfig?.key === 'tagId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort(' previous_employees')}>
                                Previous Employees
                                {sortConfig?.key === ' previous_employees' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('notes')}>
                                Notes
                                {sortConfig?.key === 'notes' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredEquipment_Removeds.map((equipment_Removed, index) => (
                            <tr key={equipment_Removed.id} onClick={() => handleEditEquipment_Removed(equipment_Removed)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="equipment_Removed-Item">{capitalize(equipment_Removed.Item)}</td>
                                <td className="equipment_Removed-tagId">{equipment_Removed.tagId}</td>
                                <td className="equipment_Removed-brand">{equipment_Removed.brand}</td>
                                <td className="equipment_Removed-model">{equipment_Removed.model}</td>
                                <td className="equipment_Removed-serial_Number">{capitalize(equipment_Removed.serial_Number)}</td>
                                <td className="equipment_Removed-price">${formatPrice(equipment_Removed.price)}</td>
                                <td className="equipment_Removed-store">{equipment_Removed.store}</td>
                                <td className="equipment_Removed-purchase_date">{equipment_Removed.purchase_date ? formatDate(equipment_Removed.purchase_date) : 'No Date'}</td>
                                <td className="equipment_Removed-assignment_date">{equipment_Removed.assignment_date ? formatDate(equipment_Removed.assignment_date) : 'No Date'}</td>
                                <td className="equipment_Removed-available">{equipment_Removed.available ? 'Yes' : 'No'} </td>
                                <td className="equipment_Removed-status">{"Broken"} </td>
                                <td className="equipment_Removed-previous_employees">{equipment_Removed.previous_employees}</td>
                                <td className="equipment_Removed-location">{equipment_Removed.location}</td>
                                <td className="equipment_Removed-notes">{equipment_Removed.notes}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showEquipment_RemovedAddOverlay && (

                <div className="equipment_Removed_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("equipment_Removed_add_overlay")) {
                        setShowEquipment_RemovedAddOverlay(false);
                        clearNewEquipment_Removed();
                        setSelectedEquipment_Removed(null);
                        setIsEditing(false);
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Equipment Removed' : 'Add New Equipment Removed'}</h2>
                            <button onClick={() => {
                                setShowEquipment_RemovedAddOverlay(false);
                                clearNewEquipment_Removed();
                                setSelectedEquipment_Removed(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>Item:</label><input name="Item" placeholder="Item" value={isEditing ? selectedEquipment_Removed.Item : newEquipment_Removed.Item} onChange={handleInputChange} required />
                        <label>TAG:</label><input name="tagId" placeholder="TAG" value={isEditing ? selectedEquipment_Removed.tagId : newEquipment_Removed.tagId} onChange={handleInputChange} />
                        <label>Brand:</label><input name="brand" placeholder="brand" value={isEditing ? selectedEquipment_Removed.brand : newEquipment_Removed.brand} onChange={handleInputChange} />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedEquipment_Removed.model : newEquipment_Removed.model} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedEquipment_Removed.serial_Number : newEquipment_Removed.serial_Number} onChange={handleInputChange} required />
                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedEquipment_Removed.price : newEquipment_Removed.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedEquipment_Removed.store : newEquipment_Removed.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedEquipment_Removed.purchase_date : newEquipment_Removed.purchase_date}
                            onChange={handleInputChange}

                        />

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedEquipment_Removed.assignment_date : newEquipment_Removed.assignment_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedEquipment_Removed.available : newEquipment_Removed.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Previous Employee:</label>
                        <select
                            name="previous_employees"
                            value={isEditing ? selectedEquipment_Removed.previous_employees : newEquipment_Removed.previous_employees}
                            onChange={handleInputChange}
                        >
                            <option value="">Select a Employee:</option>
                            <option value="none">Select a Previous Employee:</option>
                            {PreviousEmployees.length > 0 || user.length > 0 ? (
                                [...PreviousEmployees, ...user]
                                    .sort((a, b) => {
                                        const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                        const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                        return nameA.localeCompare(nameB);
                                    })
                                    .map((employee) => (
                                        <option
                                            key={employee.id}
                                            value={`${capitalize(employee.first_name)} ${capitalize(employee.last_name)}`}
                                        >
                                            {capitalize(employee.first_name)} {capitalize(employee.last_name)}
                                        </option>
                                    ))
                            ) : (
                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/* N/A */}
                        </select>


                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedEquipment_Removed.location : newEquipment_Removed.location} onChange={handleInputChange} />
                        <label>Notes:</label><input name="notes" placeholder="Notes" value={isEditing ? selectedEquipment_Removed.notes : newEquipment_Removed.notes} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-equipment_Removed" onClick={isEditing ? handleUpdateEquipment_Removed : handleAddEquipment_Removed} >
                                {isEditing ? 'Update Equipment Removed' : 'Add Equipment Removed'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-equipment_Removed"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this equipment_Removed?')) {
                                            handleDeleteEquipment_Removed();
                                        }
                                    }}
                                >
                                    Delete Equipment Removed
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );

};

export default Equipment_Removed;

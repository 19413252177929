// SearchBar.js
import React from 'react';

function SearchBar({ onSearch }) {
  const handleChange = (e) => {
    onSearch(e.target.value);
  };

  return (
    <input
      type="text"
      placeholder="Search..."
      onChange={handleChange}
      style={{
        fontSize: '14px',
        padding: '8px 8px',
        width: '200px', // Ajusta según tus necesidades
      }}
    />
  );
}

export default SearchBar;


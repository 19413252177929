import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { listReports } from '../../../graphql/queries';
import { useNavigate } from 'react-router-dom';
import './ReportsList.css';
import ReportModal from '../ReportModal/ReportModal';
import * as XLSX from 'xlsx';
import { FaFileExcel } from 'react-icons/fa';
import { useMemo } from 'react';
import moment from 'moment';

const ReportsList = () => {
  const [reports, setReports] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [fetchReports] = useLazyQuery(listReports);

  // Function to recursively fetch all pages of reports
  const loadAllReports = async () => {
    let allReports = [];
    let nextToken = null;
    setLoading(true);

    try {
      do {
        const { data } = await fetchReports({
          variables: {
            limit: 100, // Adjust limit as necessary
            nextToken: nextToken,
          },
        });

        if (data && data.listReports && data.listReports.items) {
          allReports = [...allReports, ...data.listReports.items];
          nextToken = data.listReports.nextToken; // Get the nextToken for pagination
        } else {
          nextToken = null;
        }
      } while (nextToken);

      setReports(allReports);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAllReports(); // Load all reports when the component mounts
  }, []);

  const handleDeleteReport = (reportId) => {
    setReports(reports.filter(report => report.id !== reportId));
  };

  const handleUpdateReport = (updatedReport) => {
    setReports(reports.map(report => report.id === updatedReport.id ? updatedReport : report));
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSaveAsExcel = async () => {
    try {
      if (reports.length > 0) {
        // Sort the reports by Timestamp in ascending order (earliest first)
        const sortedReports = [...reports].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        // Prepare the data for Excel export with custom column titles
        const worksheetData = sortedReports.map(report => ({
          Timestamp: report.CreatedAt,
          'Email Address': report.Username,
          Date: report.Date,
          'Navigator Name': report.Navigator,
          'Is this an outreach/colocation/phone bank event? (one-on-one interaction report)': report.outreachEvent,
          year_contract: report.year_contract,
          'How many flyers did you give out or drop off?': report.flyers,
          'For existing consumers or follow-ups, how many did you attempt to contact by phone, but they did not respond?': report.Number_FollowUp_Attempt_Contact_NoResponse,
          'How many MAC touches have you conducted today?': report.MACtouches,
          name: report.name,
          lastName: report.lastName,
          'Phone Number: ex. 123-456-7899': report.phone,
          'Address:': report.address,
          'City:': report.city,
          'County:': report.county,
          'Email:': report.email,
          'Preferred language:': report.prefered_lang,
          'Is this a Commit Card?': report.commitCard,
          'Card Number:': report.commitcardNo,
          'Signature:': report.signature,
          'Best time to call:': report.bestTimeToCall,
          'Notes:  ': report.CommitCardNotes,
          'No-Show?': report.no_show,
          'Consumer authorization on file?': report.ConsumerAuthOnFile,
          'Does your  employer offer Health Coverage? Remember to review cost and options: https://marketplace.cms.gov/applications-and-forms/employer-coverage-tool.pdf\n""AFFORDABLE"" PLANS AND THE 8.39% STANDARD:   https://www.healthreformbeyondthebasics.org/wp-content/uploads/2\nREFERENCE_YearlyGuidelines_CY2024.pdf': report.employerCoverage,
          'Cost to Employee Only?': report.cost_to_employee,
          'Is coverage offered to family?': report.familyCovered,
          'Cost for family coverage': report.amountFamCoverage,
          'Number of people in household:': report.NumPeopleHousehold,
          '# of people applying': report.NumPeopleApplying,
          Adults: report.NumAdults,
          Children: report.NumChildren,
          'Does Client Need Employee Tool?': report.EmployeeTool,
          'Income type': report.IncomeType,
          'Income Amount': report.IncomeAmount,
          Frequency: report.PayFrequency,
          'Applied for:': report.coverageAppliedFor,
          'New or ReEnrollment': report.New_Re_Enrollment,
          'Did Client Enroll?': report.clientEnroll,
          'How Many QHPs?': report.QHP_Amount,
          'Did Client Complete Medicaid/Medicare/CHIP': report.ClientCompleteMMC,
          'Was this an Appeal?': report.AppealQuestion,
          'Does Client Need Call Back or Reschedule': report.RescheduleQuestion,
          'Reason (Select one or more below)': report.reschedule_reason,
          '1. Addressing general inquiries about health insurance options.': report.one2oneInquiry,
          'A. Health Insurance literacy': report.HealthInsuranceLiteracy,
          'B. Locating providers': report.Locating_Providers,
          'C. Billing and payment questions': report.Billing_Payment_Questions,
          'D. Evaluating health care options using tools and information available through a consumer\'s health plan': report.EvaluateHealtcareOptionsUsingTools,
          'E. Accessing preventative health services': report.AccessingPreventativeHealthServices,
          'A. Healthcare.gov Account Creation or Troubleshooting': report.HealtcareGovAcc,
          'If account creation, number of accounts created': report.createdAccounts,
          'If account troubleshooting, number of accounts': report.troubleshootedAccounts,
          'B. Marketplace Eligibility Assessment and Results Review': report.MarketplaceEligibility,
          'C. Marketplace Plan Compare': report.MarketPlanCompare,
          'A. Electronic QHP Enrollment using Healthcare.gov': report.qhp_enrollment_hcgov,
          'B. Telephonic QHP Enrollment using Marketplace Call Center': report.elctronic_qhp_enrollment_marketplaceCallCenter,
          'C. Written QHP Enrollment using a paper Marketplace Application': report.written_qhp_enrollment_hcgov,
          'A. Medicaid/CHIP applications or referrals': report.medicaid_chip_app_ref,
          'A. Complex Case Help Center Assistance and Referrals': report.complex_case_help_center,
          'B. Data matching issues/Periodic data matching issues assistance': report.data_matchingIssues,
          'C. SEP eligibility troubleshooting assistance': report.SEP_eligibility,
          'D. Employer-sponsored coverage issues assistance': report.Employer_sponsored_coverage_issues,
          'E. APTC/CSR assistance': report.APTC_CSR_assistance,
          'F. Other': report.Complex_Cases_Other,
          'Answering questions about and/or making referrals to:': report.Answering_questions_about,
          'A. Marketplace tax forms (1095-A)': report.Marketplace_tax_forms,
          'B. Filing Marketplace exemptions': report.Filing_Marketplace,
          'C. Submitting Marketplace or insurance coverage appeals': report.Submitting_Marketplace,
          'Assisted with other consumer information': report.Assisted_with_other_consumer_information,
          'Pertinent information for tracking (i.e. DOB, family member DOB, medications, preferred doctors , etc.)': report.pertinent_info_tracking,
          'Where did you Hear from us?': report.hear_from_us,
          'What Event Did You Attend and See Us At? (Ex. Floresville Health Fair on 7/19/22)': report.event_hear_abt_us,
          'How or Where on Facebook Did You See Us?  (ex. Facebook Live yesterday, FB Ad about Chip, FB Link from friend, FB Post about changes - 7/12)': report.facebook_hear_abt_us,
          'How or Where on Instagram Did You See Us?  (ex. Instagram Live yesterday, Instagram Ad about Chip, Instagram Link from friend, Instagram Post about changes - 7/12)': report.insta_hear_abt_us,
          'SAC - San Antonio College                          (Ex.  Event 7/12, Flyer at Student Services, Teacher - Ms. Z, Staff Mr. Lopez)': report.SAC_hear_abt_us,
          'How or Where on Twitter Did You See Us?  (ex.  Twitter Live yesterday,  Twitter Ad about Chip,  Twitter Link from friend, Twitter Post about changes - 7/12)': report.twitter_hear_abt_us,
          'What TV Station Did You See Us In?             (Ex. Univision - Phone Bank 7/21, KSAT - News 7/1, Telemundo - Commercial 7/2)': report.tv_hear_abt_us,
          'Which Hospital Did You See Us At? (Baptist Mission Trails, Cuero Regional, MHM -Floyd Curl)': report.hospital_hear_abt_us,
          'Which Library Did You See Us At?': report.library_opt,
          'Which Workforce and When? (Ex. Medical 7/20 -(same as Datapoint), Marbach 7/1, Flores 7/10)': report.workforce_hear_abt_us,
          'What Table Presentation Did You Attend? (Ex. Community Event at Madonna 7/21 or Parent Presentation at Lanier 7/1/22)': report.tablepresentation_hear_abt_us,
          'Who Referred You?': report.referral_hear_abt_us,
          'What Newspaper did you see our ad on? (Ex. San Antonio  Express, Karnes Daily...)': report.newspaper_hear_abt_us,
          'Which Radio Station Did You Here US? (Ex. La Nuestra or Tejano - in San Antonio or Karnes)': report.radio_hear_abt_us,
          'Where Did You Find or See Our Flyer? (Ex. MAUC, WSA, Culebra, Cody Library)': report.flyer_hear_abt_us,
          'County ': report.county2,
          'Zip Code': report.zip_code,
          'Any Notes': report.finalNotes,
        }));

        // Convert JSON to sheet
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Reports');

        // Write file
        XLSX.writeFile(workbook, 'ReportsData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  const filteredReports = reports.filter(report => {
    const fieldValue = report[searchField]?.toString().toLowerCase();
    if (searchField === 'Date') {
      const dateValue = new Date(report.Date).toISOString().split('T')[0];
      return dateValue.includes(searchQuery);
    }
    return fieldValue.includes(searchQuery.toLowerCase());
  });


  const sortedAndFilteredReports = useMemo(() => {
    let filtered = reports.filter(report => {
      const fieldValue = report[searchField]?.toString().toLowerCase();
      if (searchField === 'Date') {
        const dateValue = new Date(report.Date).toISOString().split('T')[0];
        return dateValue.includes(searchQuery);
      }
      return fieldValue.includes(searchQuery.toLowerCase());
    });

    if (sortConfig !== null) {
      filtered = filtered.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filtered;
  }, [reports, sortConfig, searchQuery, searchField]);


  return (
    <div className="reports">
      <header className="reports-header">
        <h1>Reports Update</h1>
      </header>
      <div className="reports-search">
        <button onClick={() => navigate('/reports')} className="back-reports-button">
          Reports
        </button>
        {searchField === 'Date' ? (
          <input
            type="date"
            value={searchQuery}
            onChange={handleSearch}
          />
        ) : (
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
          />
        )}
        <select onChange={(e) => setSearchField(e.target.value)}>
          <option value="name">Name</option>
          <option value="lastName">Last Name</option>
          <option value="Navigator">Navigator</option>
          <option value="Date">Date</option>
          <option value="Timestamp">Timestamp</option>
          <option value="phone">Phone</option>
        </select>
        <button onClick={handleSaveAsExcel} className="excel-button">
          <FaFileExcel /> Export
        </button>
      </div>
      {/* Render the table regardless of loading state */}
      <div className="scrollable-container">
        <table>
          <thead>
            <tr>
              <th className='colTimestamp' onClick={() => requestSort('Timestamp')}>
                Timestamp
                {sortConfig?.key === 'Timestamp' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='colEmail' onClick={() => requestSort('email')}>
                Email
                {sortConfig?.key === 'email' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='colDate' onClick={() => requestSort('Date')}>
                Date
                {sortConfig?.key === 'Date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='colNav' onClick={() => requestSort('Navigator')}>
                Navigator
                {sortConfig?.key === 'Navigator' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='coloutreachEvent' onClick={() => requestSort('outreachEvent')}>
                Outreach Event
                {sortConfig?.key === 'outreachEvent' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='colName' onClick={() => requestSort('name')}>
                Name
                {sortConfig?.key === 'name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='colLastName' onClick={() => requestSort('lastName')}>
                Last Name
                {sortConfig?.key === 'lastName' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='colPhone' onClick={() => requestSort('phone')}>
                Phone
                {sortConfig?.key === 'phone' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='colAddress' onClick={() => requestSort('address')}>
                Address
                {sortConfig?.key === 'address' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='addressing_General_Inquiries' onClick={() => requestSort('one2oneInquiry')}>
                1. Addressing general inquiries about health insurance options.
                {sortConfig?.key === 'one2oneInquiry' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className='HealthInsuranceLiteracy' onClick={() => requestSort('HealthInsuranceLiteracy')}>
                A. Health Insurance literacy
                {sortConfig?.key === 'HealthInsuranceLiteracy' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th>B. Locating providers</th>
              <th>C. Billing and payment questions</th>
              <th>D. Evaluating health care options using tools and information available through a consumer's health plan</th>
              <th>E. Accessing preventative health services</th>
              <th>A. Healthcare.gov Account Creation or Troubleshooting</th>
              <th>B. Marketplace Eligibility Assessment and Results Review</th>
              <th>C. Marketplace Plan Compare</th>
              <th>A. Electronic QHP Enrollment using Healthcare.gov</th>
              <th>B. Telephonic QHP Enrollment using Marketplace Call Center</th>
              <th>C. Written QHP Enrollment using a paper Marketplace Application</th>
              <th>A. Medicaid/CHIP applications or referrals</th>
              <th>A. Complex Case Help Center Assistance and Referrals</th>
              <th>B. Data matching issues/Periodic data matching issues assistance</th>
              <th>C. SEP eligibility troubleshooting assistance</th>
              <th>D. Employer-sponsored coverage issues assistance</th>
              <th>E. APTC/CSR assistance</th>
              <th>F. Other</th>
              <th>Agent/broker</th>
              <th>Issuer</th>
              <th>Medicare</th>
              <th>Other consumer assistance/health insurance program</th>
              <th>State Departments of Insurance</th>
              <th>A. Marketplace tax forms (1095-A)</th>
              <th>B. Filing Marketplace exemptions</th>
              <th>C. Submitting Marketplace or insurance coverage appeals</th>
              <th>Assisted with other consumer information</th>
              <th>QHP Amount</th>
              <th>Applied For</th>
              <th>Year Contract</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="9" className="loading-text">Loading...</td>
              </tr>
            ) : (sortedAndFilteredReports.map(report => (
              <tr key={report.id} onClick={() => setSelectedReport(report)}>
                <td className='colTimestamp'>
                  {
                    // Parse the Timestamp, subtract 5 hours, and format it
                    (() => {
                      const originalTimestamp = report.Timestamp.slice(0, -5); // "2024-08-30T13:43:36.806"
                      const date = new Date(originalTimestamp);
                      date.setTime(date.getTime() - 5 * 60 * 60 * 1000); // Subtract 5 hours

                      // Format back to the desired string (including milliseconds)
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const day = String(date.getDate()).padStart(2, '0');
                      const hours = String(date.getHours()).padStart(2, '0');
                      const minutes = String(date.getMinutes()).padStart(2, '0');
                      const seconds = String(date.getSeconds()).padStart(2, '0');

                      // Construct the updated timestamp string
                      const updatedTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

                      return updatedTimestamp;
                    })()
                  }
                </td>
                <td className='colEmail'>{report.email}</td>
                <td>{moment(report.Date).format('MM-DD-YYYY')}</td>
                <td className='colNav'>{report.Navigator}</td>
                <td className='coloutreachEvent'>{report.outreachEvent}</td>
                <td className='colName'>{report.name}</td>
                <td className='colLastName'>{report.lastName}</td>
                <td className='colPhone'>{Math.round(report.phone)}</td>
                <td className='colAddress'>{report.address}</td>
                <td>{Math.round(report.one2oneInquiry)}</td>
                <td>{Math.round(report.HealthInsuranceLiteracy)}</td>
                <td>{Math.round(report.Locating_Providers)}</td>
                <td>{Math.round(report.Billing_Payment_Questions)}</td>
                <td>{Math.round(report.EvaluateHealtcareOptionsUsingTools)}</td>
                <td>{Math.round(report.AccessingPreventativeHealthServices)}</td>
                <td>{report.HealtcareGovAcc}</td>
                <td>{Math.round(report.MarketplaceEligibility)}</td>
                <td>{Math.round(report.MarketPlanCompare)}</td>
                <td>{Math.round(report.qhp_enrollment_hcgov)}</td>
                <td>{Math.round(report.elctronic_qhp_enrollment_marketplaceCallCenter)}</td>
                <td>{Math.round(report.written_qhp_enrollment_hcgov)}</td>
                <td>{Math.round(report.medicaid_chip_app_ref)}</td>
                <td>{Math.round(report.complex_case_help_center)}</td>
                <td>{report.data_matchingIssues}</td>
                <td>{report.SEP_eligibility}</td>
                <td>{report.Employer_sponsored_coverage_issues}</td>
                <td>{report.APTC_CSR_assistance}</td>
                <td>{report.Complex_Cases_Other}</td>
                <td>{report.Answering_questions_about}</td>
                <td>{report.Answering_questions_about}</td>
                <td>{report.Answering_questions_about}</td>
                <td>{report.Answering_questions_about}</td>
                <td>{report.Answering_questions_about}</td>
                <td>{Math.round(report.Marketplace_tax_forms)}</td>
                <td>{Math.round(report.Filing_Marketplace)}</td>
                <td>{Math.round(report.Submitting_Marketplace)}</td>
                <td>{Math.round(report.Assisted_with_other_consumer_information)}</td>
                <td>{Math.round(report.QHP_Amount)}</td>
                <td>{report.coverageAppliedFor}</td>
                <td>{report.year_contract}</td>
              </tr>
            ))
            )}
          </tbody>
        </table>
      </div>
      {/* Show total report count when data is loaded */}
      <p className='TotalReports'>
        Total Reports: {loading ? 'Loading...' : sortedAndFilteredReports.length}
      </p>

      {/* Display loading text until data is fetched */}
      {selectedReport && (
        <ReportModal
          report={selectedReport}
          onClose={() => setSelectedReport(null)}
          onDelete={handleDeleteReport}
          onUpdate={handleUpdateReport}
        />
      )}
    </div>
  );
};

export default ReportsList;
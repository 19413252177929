import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { createScheduleTemplate, updateScheduleTemplate, deleteScheduleTemplate } from '../../../graphql/mutations'; // Adjust the path if necessary
import './TemplateModal.css';

const initialDays = [
    { day: 'Saturday', startTime: '', endTime: '', isOff: false },
    { day: 'Sunday', startTime: '', endTime: '', isOff: false },
    { day: 'Monday', startTime: '', endTime: '', isOff: false },
    { day: 'Tuesday', startTime: '', endTime: '', isOff: false },
    { day: 'Wednesday', startTime: '', endTime: '', isOff: false },
    { day: 'Thursday', startTime: '', endTime: '', isOff: false },
    { day: 'Friday', startTime: '', endTime: '', isOff: false },
];
const TemplateModal = ({ isOpen, onClose, templates, refetchTemplates }) => {
    const [templateName, setTemplateName] = useState('');
    const [days, setDays] = useState([...initialDays]);
    const [totalHours, setTotalHours] = useState('');
    const [editingTemplateId, setEditingTemplateId] = useState(null);

    const [createTemplate] = useMutation(createScheduleTemplate);
    const [updateTemplate] = useMutation(updateScheduleTemplate);
    const [deleteTemplate] = useMutation(deleteScheduleTemplate);

    // Utility to calculate total hours for the template
    const calculateTotalHours = () => {
        const total = days.reduce((sum, day) => {
            if (!day.isOff && day.startTime && day.endTime) {
                const [startHour, startMinute] = day.startTime.split(':').map(Number);
                const [endHour, endMinute] = day.endTime.split(':').map(Number);

                const start = startHour + startMinute / 60;
                const end = endHour + endMinute / 60;

                const dailyHours = Math.max(0, end - start - 1); // Subtract 1 hour for breaks
                return sum + dailyHours;
            }
            return sum;
        }, 0);
        setTotalHours(total.toFixed(2));
    };

    // Handle changes to day configuration
    const handleDayChange = (index, field, value) => {
        const updatedDays = [...days];
        updatedDays[index][field] = value;
        setDays(updatedDays);
        calculateTotalHours();
    };

    // Reset form fields
    const resetForm = () => {
        setTemplateName('');
        setDays([...initialDays]);
        setTotalHours('');
        setEditingTemplateId(null);
    };

    // Add or update a template
    const handleAddOrUpdateTemplate = async () => {
        if (!templateName || days.some((day) => !day.isOff && (!day.startTime || !day.endTime)) || !totalHours) {
            alert('Please provide a valid template name, complete shifts, and total hours.');
            return;
        }

        const input = {
            name: templateName,
            days: days.map((day) => ({
                day: day.day,
                shift: day.isOff ? 'OFF' : `${day.startTime || '07:00'}-${day.endTime || '22:00'}`,
            })),
            totalHours: parseFloat(totalHours),
        };

        try {
            if (editingTemplateId) {
                await updateTemplate({ variables: { input: { id: editingTemplateId, ...input } } });
                alert('Template updated successfully!');
            } else {
                await createTemplate({ variables: { input } });
                alert('Template created successfully!');
            }
            refetchTemplates && refetchTemplates();
        } catch (error) {
            console.error('Error saving template:', error);
            alert('An error occurred while saving the template.');
        }
        window.location.reload(); // Refresh the page to remove the deleted template

        resetForm();
        onClose();
    };

    // Delete a template
    const handleDeleteTemplate = async (id) => {
        if (!window.confirm('Are you sure you want to delete this template?')) return;

        try {
            await deleteTemplate({ variables: { input: { id } } });
            alert('Template deleted successfully!');
            refetchTemplates && refetchTemplates();
            window.location.reload(); // Refresh the page to remove the deleted template
        } catch (error) {
            console.error('Error deleting template:', error);
            alert('An error occurred while deleting the template.');
        }
    };

    // Prepare modal for editing an existing template
    const handleEditClick = (template) => {
        setTemplateName(template.name);
        setDays(
            template.days.map((day) => ({
                day: day.day,
                startTime: day.shift === 'OFF' ? '' : day.shift.split('-')[0],
                endTime: day.shift === 'OFF' ? '' : day.shift.split('-')[1],
                isOff: day.shift === 'OFF',
            }))
        );
        setTotalHours(template.totalHours);
        setEditingTemplateId(template.id);
    };

    // Close the modal and reset form when closing
    const handleCloseModal = () => {
        resetForm(); // Reset modal to initial state
        onClose();   // Call the parent onClose handler
    };

    // Close the modal when clicking outside of it
    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('template-modal-overlay')) {
            handleCloseModal();
        }
    };

    if (!isOpen) return null;
    return (
        <div className="template-modal-overlay" onClick={handleOverlayClick}>
            <div className="template-modal">
                {/* Add New Template Section */}
                <div className="template-form">
                    <h3>{editingTemplateId ? 'Edit Template' : 'Add New Template'}</h3>
                    <label>Template Name</label>
                    <input
                        type="text"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                    />

                    <label>Day Shifts</label>
                    <div className="day-shifts">
                        {days.map((day, index) => (
                            <div key={day.day} className="day-shift-item">
                                <label>{day.day}</label>
                                <input
                                    type="checkbox"
                                    checked={day.isOff}
                                    onChange={(e) => handleDayChange(index, 'isOff', e.target.checked)}
                                />
                                {day.isOff ? (
                                    <span>Day Off</span>
                                ) : (
                                    <>
                                        <input
                                            type="time"
                                            value={day.startTime || ''}
                                            onChange={(e) => handleDayChange(index, 'startTime', e.target.value)}
                                        />
                                        <input
                                            type="time"
                                            value={day.endTime || ''}
                                            onChange={(e) => handleDayChange(index, 'endTime', e.target.value)}
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                    </div>

                    <label>Total Hours</label>
                    <input type="text" value={totalHours} readOnly />

                    <button onClick={handleAddOrUpdateTemplate} className="add-template-button">
                        {editingTemplateId ? 'Update Template' : 'Add Template'}
                    </button>
                </div>

                {/* Existing Templates Section */}
                <div className="template-list">
                    <h3>Existing Templates</h3>
                    <ul>
                        {templates.map((template) => (
                            <li key={template.id} className="template-item">
                                <span>{template.name}</span>
                                <div>
                                    <button onClick={() => handleEditClick(template)}>Edit</button>
                                    <button onClick={() => handleDeleteTemplate(template.id)}>Delete</button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );

};

export default TemplateModal;

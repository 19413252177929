import React, { useState } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { listOptOutClients, GET_CLIENTFIX2 } from '../../graphql/queries'; // Adjust the path as necessary
import { CREATE_CLIENTFIX2, DELETE_OPT_OUT_CLIENT } from '../../graphql/mutations'; // Add appropriate mutations
import './OptOut.css';

const OptOutClients = () => {
  const [nextToken, setNextToken] = useState(null);
  const [clients, setClients] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const itemsPerPage = 10;

  const { loading, error, data, fetchMore } = useQuery(listOptOutClients, {
    variables: { limit: itemsPerPage, nextToken: null },
    onCompleted: (data) => {
      setClients(data.listOptOutClients.items);
      setNextToken(data.listOptOutClients.nextToken);
    },
  });

  const [fetchClientExists] = useLazyQuery(GET_CLIENTFIX2);
  const [createClient] = useMutation(CREATE_CLIENTFIX2);
  const [deleteOptOutClient] = useMutation(DELETE_OPT_OUT_CLIENT);

  const loadMore = () => {
    if (!nextToken || loadingMore) return;

    setLoadingMore(true);
    fetchMore({
      variables: { limit: itemsPerPage, nextToken },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setClients([...clients, ...fetchMoreResult.listOptOutClients.items]);
        setNextToken(fetchMoreResult.listOptOutClients.nextToken);
        setLoadingMore(false);
      },
    });
  };

  const handleRecoverClient = async () => {
    if (!selectedClient) return;

    try {
      // Check if the client already exists in the ClientFix2 table
      const { data: clientData } = await fetchClientExists({
        variables: { id: selectedClient.id },
      });

      if (clientData?.getClientFix2) {
        console.warn('Client already exists in the ClientFix2 table. Removing from opt-out list only.');

        // Remove the client from the opt-out list
        await deleteOptOutClient({
          variables: {
            input: {
              id: selectedClient.id, // Ensure this matches your GraphQL schema
            },
          },
        });
        
        // Update the clients list
        setClients(clients.filter((client) => client.id !== selectedClient.id));
        setIsModalOpen(false);
      } else {
        // Create a client using the selected client's information
        await createClient({
          variables: {
            input: {
              id: selectedClient.id,
              name: selectedClient.name,
              last_name: selectedClient.last_name,
              address: selectedClient.address,
              city: selectedClient.city,
              county: selectedClient.county,
              zip_code: selectedClient.zip_code,
              prefered_lang: selectedClient.prefered_lang,
              email: selectedClient.email,
              insurance_picked: selectedClient.insurance_picked,
              plan: selectedClient.plan,
              payment_per_month: selectedClient.payment_per_month,
              phone: selectedClient.phone,
              navigator: selectedClient.navigator,
              callStatus: 'Active', // Set status as active
            },
          },
        });

        // Remove the client from the opt-out list
        await deleteOptOutClient({
          variables: {
            input: {
              id: selectedClient.id, // Ensure this matches your GraphQL schema
            },
          },
        });
        
        // Update the clients list
        setClients(clients.filter((client) => client.id !== selectedClient.id));
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error('Error recovering client:', error);
    }
  };

  if (loading) return <div className="optOut-loading">Loading...</div>;
  if (error) return <div className="optOut-error">Error: {error.message}</div>;

  return (
    <div className="optOut-container">
      <header className="optOut-header">
        <h1>Opt-Out Clients</h1>
      </header>
      <div className="optOut-table-container">
        <table className="optOut-table">
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Reason for Opting Out</th>
              <th>Opted Out Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.length > 0 ? (
              clients.map((client) => (
                <tr key={client.id}>
                  <td>{`${client.name || 'N/A'} ${client.last_name || ''}`}</td>
                  <td>{client.phone || 'N/A'}</td>
                  <td>{client.email || 'N/A'}</td>
                  <td>{client.optOutReason || 'No reason provided'}</td>
                  <td>{client.optOutDate || 'N/A'}</td>
                  <td>
                    <button
                      className="optOut-recover-button"
                      onClick={() => {
                        setSelectedClient(client);
                        setIsModalOpen(true);
                      }}
                    >
                      Recover Client
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No opt-out clients found.</td>
              </tr>
            )}
          </tbody>
        </table>
        {nextToken && (
          <div className="optOut-pagination">
            <button className="optOut-pagination-button" onClick={loadMore}>
              {loadingMore ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="optOut-modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div
            className="optOut-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Recover Client</h3>
            <p>Are you sure you want to recover {selectedClient?.name}?</p>
            <div className="optOut-modal-actions">
              <button className="optOut-modal-confirm" onClick={handleRecoverClient}>
                Recover Client
              </button>
              <button
                className="optOut-modal-cancel"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OptOutClients;

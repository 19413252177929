import React, { useState, useEffect } from 'react';
import { FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './UserTab.css';
import { capitalize } from '../../components/common/capitalize';

import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

function UserTab() {
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);

      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0];

      setUserDetails({
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        userId: signInDetails.loginId
      });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleUserTabClick = () => {
    if (userGroups.includes('Admin')) {
      navigate('/admin-home'); // Navigate to /admin-home if the user is in the 'admin' group
    } else {
      console.log('Access denied. User is not an admin.');
    }
  };

  return (
    <div className="user-tab" onClick={handleUserTabClick}> {/* Add onClick to the user tab */}
      <div className="notification-icon">
        <FaBell />
        <span className="notification-count">0</span>
      </div>
      <div className="user-info">
        {userDetails ? (
          <>
            <div className="user-name">
              {userDetails.firstName + ' ' + userDetails.lastName[0] + '.'}
            </div>
            <div className="user-role">{userGroups[0]}</div>
          </>
        ) : (
          <div>Loading user information...</div>
        )}
      </div>
    </div>
  );
}

export default UserTab;

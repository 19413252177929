import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listPrinters, listUsers, listSupplies, listDeleteEquipmentHistories } from '../../../graphql/queries';
import { createDeleteEquipmentHistory, createPrinter, deletePrinter, updatePrinter, updateSupplies } from '../../../graphql/mutations';
import './Printer.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';

const Printer = () => {

    const [printer, setPrinter] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showPrinterAddOverlay, setShowPrinterAddOverlay] = useState(false);
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);


    const [newPrinter, setNewPrinter] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        charger_cable: '',
        Connector_cable: '',
        wireless: '',
        black_ink: '',
        color_ink: '',
        black_refill_dates: [],  // Inicializado como un array vacío
        color_refill_dates: [],
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: '',
        status: '',
        assigned_to: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [refillColorError, setRefillColorError] = useState('');
    const [refillBlackError, setRefillBlackError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);
    const [newBlackRefillDate, setNewBlackRefillDate] = useState('');
    const [newColorRefillDate, setNewColorRefillDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [officeSupplies, setOfficeSupplies] = useState([]); // Lista completa de office supplies
    const [selectedSupplyBlack, setSelectedSupplyBlack] = useState(null); // Office supply seleccionado
    const [selectedSupplyColor, setSelectedSupplyColor] = useState(null); // Office supply seleccionado
    const [updSupply] = useMutation(updateSupplies);


    const { loading2, error: userError2, data: suppliesData } = useQuery(listSupplies);

    // Filtro para verificar las ink disposibles en Supplies
    const filteredBlackSupplies = suppliesData?.listSupplies?.items?.filter(
        (supply) => {
            // Validar que printer exista y tenga la propiedad brand
            if (!selectedPrinter || !selectedPrinter.brand || typeof selectedPrinter.brand !== "string") return false;
            // Aseguramos que name sea un string
            if (typeof supply.name !== "string") return false;

            // Convertir el name a minúsculas para facilitar las comparaciones
            const lowerName = supply.name.toLowerCase();

            // Verificar que contenga "black" e "ink"
            const containsBlack = lowerName.includes("black");
            const containsInk = lowerName.includes("ink");

            // Extraer marca del supply (primeras palabras antes de un número)
            const brandMatch = supply.name.match(/^[a-zA-Z]+/); // Encuentra la primera palabra en el nombre
            const supplyBrand = brandMatch ? brandMatch[0].toLowerCase() : null;

            // Comparar la marca del supply con la marca de la impresora
            const isBrandMatch = supplyBrand.toLowerCase() === selectedPrinter?.brand?.split(' ')[0].toLowerCase();

            // Verificar si está disponible
            const isAvailable = supply.available === true;

            // Aplicar las condiciones finales
            return containsBlack && containsInk && isAvailable && isBrandMatch;
        }
    ) || [];

    const filteredColorSupplies = suppliesData?.listSupplies?.items?.filter(
        (supply) => {
            // Validar que selectedPrinter exista y tenga la propiedad brand
            if (!selectedPrinter || !selectedPrinter.brand || typeof selectedPrinter.brand !== "string") return false;

            // Aseguramos que name sea un string
            if (typeof supply.name !== "string") return false;

            // Convertir el name a minúsculas para facilitar las comparaciones
            const lowerName = supply.name.toLowerCase();

            // Verificar que NO contenga "black" pero SÍ contenga "ink"
            const doesNotContainBlack = !lowerName.includes("black");
            const containsInk = lowerName.includes("ink");

            // Extraer marca del supply (primeras palabras antes de un número)
            const brandMatch = supply.name.match(/^[a-zA-Z]+/); // Encuentra la primera palabra en el nombre
            const supplyBrand = brandMatch ? brandMatch[0].toLowerCase() : null;

            // Comparar la marca del supply con la marca de la impresora
            const isBrandMatch = supplyBrand === selectedPrinter.brand.toLowerCase();

            // Verificar si está disponible
            const isAvailable = supply.available === true;

            // Aplicar las condiciones finales
            return doesNotContainBlack && containsInk && isAvailable && isBrandMatch;
        }
    ) || [];


    // Export Excel
    const handleSaveAsExcel = async () => {
        try {


            const printerToExport = filteredPrinters.length > 0 ? filteredPrinters : printer;

            if (printerToExport.length > 0) {
                // Ordenar los teléfonos por Timestamp en orden ascendente
                const sortedPrinter = [...printerToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedPrinter.map((printer, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': printer.tagid,
                    'Brand:': printer.brand,
                    'Available:': printer.available ? 'Yes' : 'No',
                    'Serial Number:': printer.serial_Number,
                    'Status:': printer.status,
                    'Price:': printer.price,
                    'Assigned to:': printer.assigned_to,
                    'Team:': printer.team,
                    'Location:': printer.location,
                    // Add other fields as needed

                }));


                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Printers']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 10 },
                    { wch: 15 }, // Adjust width for each column
                    { wch: 30 },
                    { wch: 10 },
                    { wch: 30 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 25 },
                    { wch: 5 },
                    { wch: 25 }
                ];
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Printers');

                // Write file
                XLSX.writeFile(workbook, 'PrintersData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditPrinter = (printer) => {
        setSelectedPrinter(printer);
        setIsEditing(true);
        setShowPrinterAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listPrinters, {
        variables: { limit: 100 },
    });

    const [addPrinter] = useMutation(createPrinter, {
        refetchQueries: [{ query: listPrinters, variables: { limit: 100 } }]
    });

    const [removePrinter] = useMutation(deletePrinter, {
        refetchQueries: [{ query: listPrinters, variables: { limit: 100 } }]
    });
    const [updPrinter] = useMutation(updatePrinter, {
        refetchQueries: [{ query: listPrinters, variables: { limit: 100 } }]
    });

    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });

    const [fetchAllPrinters, { loading: countLoading, data: AllPrintersData }] = useLazyQuery(listPrinters, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllPrinters = async () => {
            let AllPrinters = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllPrinters = [...AllPrinters, ...data.listPrinters.items];
                currentNextToken = data.listPrinters.nextToken;
            } while (currentNextToken);

            setPrinter(AllPrinters);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllPrinters();
        }
    }, [data, fetchMore]);

    const clearNewPrinter = () => {
        setNewPrinter({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            charger_cable: '',
            Connector_cable: '',
            wireless: '',
            black_ink: '',
            color_ink: '',
            black_refill_dates: [],  // Inicializado como un array vacío
            color_refill_dates: [],
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: '',
            status: '',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listPrinters.nextToken);
                return {
                    listPrinters: {
                        ...fetchMoreResult.listPrinters,
                        items: [...prevResult.listPrinters.items, ...fetchMoreResult.listPrinters.items]
                    }
                };
            }
        });
    };

    //Filtro de Printer empleando el componente useFilteredData
    const filteredPrinters = useFilteredData(printer || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;
        console.log(name)

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        if (isEditing) {
            setSelectedPrinter({
                ...selectedPrinter,
                [name]: formattedValue
            });
        } else {
            setNewPrinter({
                ...newPrinter,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleDateChange = (name, value) => {
        // Verifica que el valor no sea nulo o vacío
        if (!value?.trim()) {
            console.error("Invalid date value:", value);
            return;
        }

        // Modo de edición
        if (isEditing) {
            setSelectedPrinter((prevState) => {
                if (name === "black_refill_dates") {
                    return {
                        ...prevState,
                        black_refill_dates: [
                            ...(Array.isArray(prevState.black_refill_dates) ? prevState.black_refill_dates : []),
                            value,
                        ],
                    };
                } else if (name === "color_refill_dates") {
                    return {
                        ...prevState,
                        color_refill_dates: [
                            ...(Array.isArray(prevState.color_refill_dates) ? prevState.color_refill_dates : []),
                            value,
                        ],
                    };
                }
                return prevState; // Retorna el estado sin cambios si no coincide el nombre
            });
        }
        // Modo de creación
        else {
            if (name === "black_refill_dates") {
                setNewBlackRefillDate(value); // Actualiza el input temporalmente
                setNewPrinter((prevState) => ({
                    ...prevState,
                    black_refill_dates: [
                        ...(Array.isArray(prevState.black_refill_dates) ? prevState.black_refill_dates : []),
                        value,
                    ],
                }));
            } else if (name === "color_refill_dates") {
                setNewColorRefillDate(value); // Actualiza el input temporalmente
                setNewPrinter((prevState) => ({
                    ...prevState,
                    color_refill_dates: [
                        ...(Array.isArray(prevState.color_refill_dates) ? prevState.color_refill_dates : []),
                        value,
                    ],
                }));
            }
        }
    };


    const handleAddPrinter = () => {

        setNewBlackRefillDate('');  // Limpiar el campo de fecha
        setNewColorRefillDate('');  // Limpiar el campo de fecha   

        const chargerCableValue = newPrinter.charger_cable !== undefined && newPrinter.charger_cable !== null ? newPrinter.charger_cable : 'Yes';


        const formatDate = (date) => date ? new Date(date).toISOString().split('T')[0] : null;

        const formattedPurchase = formatDate(newPrinter.purchase_date);
        const formattedAssignment = formatDate(newPrinter.assignment_date);
        const formattedWarranty = formatDate(newPrinter.warranty_date);
        const formattedReturnable = formatDate(newPrinter.returnable_date);

        const formattedBlackInkDates = Array.isArray(newPrinter.black_refill_dates)
            ? newPrinter.black_refill_dates.map(date => formatDate(date))
            : [];

        const formattedColorInkDates = Array.isArray(newPrinter.color_refill_dates)
            ? newPrinter.color_refill_dates.map(date => formatDate(date))
            : [];

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newPrinter.price !== "" && newPrinter.price !== null && newPrinter.price != undefined) {
            priceValue = parseFloat(newPrinter.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                console.log('Price validation failed:', newPrinter.price);
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = printer.some(printer =>
            printer.tagid === newPrinter.tagid &&
            printer.serial_Number === newPrinter.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A printer with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        // Check availability first
        if (newPrinter.available === 'true') {

            // If available, status must be Inventario
            if (newPrinter.status !== 'inv') {
                setStatusError('If the Printer is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newPrinter.assigned_to !== '' && newPrinter.assigned_to !== 'n/a') {
                setStatusError('If the Printer is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newPrinter.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newPrinter.status)) {
                setStatusError('If the Printer is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        // If all validations pass, proceed with adding the printer to the database
        addPrinter({
            variables: {
                input: {
                    ...newPrinter, price: priceValue, charger_cable: chargerCableValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment,
                    black_refill_dates: formattedBlackInkDates, color_refill_dates: formattedColorInkDates
                }
            }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding printer:", error);
            });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeletePrinter = async () => {
        try {
            // Remover campos innecesarios
            const PrinterData = removeUnnecessaryFields({ ...selectedPrinter }, [
                'charger_cable',
                'Connector_cable',
                'wireless',
                'black_ink',
                'color_ink',
                'black_refill_dates',
                'color_refill_dates',
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewPrinters = Object.fromEntries(
                Object.entries(PrinterData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewPrinters);

            // Normalizar claves alternativas
            cleanedNewPrinters.tagId = cleanedNewPrinters.tagId || cleanedNewPrinters.tagid;
            delete cleanedNewPrinters.tagid;

            cleanedNewPrinters.previous_employees =
                cleanedNewPrinters.previous_employees || cleanedNewPrinters.assigned_to;
            delete cleanedNewPrinters.assigned_to;

            console.log("Despues de normalizar:", cleanedNewPrinters);

            // Añadir valores clave predeterminados
            if (!cleanedNewPrinters.Item) {
                cleanedNewPrinters.Item = "Printer"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewPrinters).forEach((key) => {
                if (cleanedNewPrinters[key] === "" || cleanedNewPrinters[key] === null) {
                    delete cleanedNewPrinters[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewPrinters },
            });

            // Eliminar el Printer
            await removePrinter({
                variables: { input: { id: cleanedNewPrinters.id } },
            });

            // Resetear estados
            setShowPrinterAddOverlay(false);
            setIsEditing(false);
            setSelectedPrinter(null);

        } catch (error) {
            console.error("Error deleting Printer", error);
        }
    };

    const handleDeleteDate = async (indexToDelete) => {
        // Confirmación opcional
        if (!window.confirm("Are you sure you want to delete this color refill date?")) {
            return;
        }

        // Indicador de carga (opcional)
        setIsUpdating(true);

        // Eliminar la fecha del estado
        const updatedDates = selectedPrinter.black_refill_dates.filter((_, index) => index !== indexToDelete);

        // Actualizar el estado del selectedPrinter
        setSelectedPrinter({
            ...selectedPrinter,
            black_refill_dates: updatedDates
        });

        // Actualizar el backend inmediatamente
        try {
            await updPrinter({
                variables: {
                    input: {
                        id: selectedPrinter.id,
                        black_refill_dates: updatedDates,
                        // Otros campos que podrían ser necesarios para la mutación
                    }
                }
            });
            console.log("Fecha eliminada y backend actualizado correctamente.");
        } catch (error) {
            console.error("Error al actualizar el backend:", error);
        } finally {
            // Finalizar el indicador de carga
            setIsUpdating(false);
        }
    };


    const handleDeleteColorDate = async (indexToDelete) => {
        // Confirmación opcional
        if (!window.confirm("Are you sure you want to delete this color refill date?")) {
            return;
        }

        // Indicador de carga (opcional)
        setIsUpdating(true);

        // Eliminar la fecha del estado
        const updatedDates = selectedPrinter.color_refill_dates.filter((_, index) => index !== indexToDelete);

        // Actualizar el estado del selectedPrinter
        setSelectedPrinter({
            ...selectedPrinter,
            color_refill_dates: updatedDates
        });

        // Actualizar el backend inmediatamente
        try {
            await updPrinter({
                variables: {
                    input: {
                        id: selectedPrinter.id,
                        color_refill_dates: updatedDates,
                        // Otros campos que podrían ser necesarios para la mutación
                    }
                }
            });
            console.log("Fecha eliminada y backend actualizado correctamente.");
        } catch (error) {
            console.error("Error al actualizar el backend:", error);
        } finally {
            // Finalizar el indicador de carga
            setIsUpdating(false);
        }
    };


    const handleUpdatePrinter = async () => {

        const formattedData_purchase = selectedPrinter.purchase_date ? new Date(selectedPrinter.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedPrinter.warranty_date ? new Date(selectedPrinter.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedPrinter.returnable_date ? new Date(selectedPrinter.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedPrinter.assignment_date ? new Date(selectedPrinter.assignment_date).toISOString().split('T')[0] : null;

        // Verificar si la fecha ya existe antes de agregarla
        const updatedBlackRefillDates = Array.isArray(selectedPrinter.black_refill_dates)
            ? [...new Set([...selectedPrinter.black_refill_dates, newBlackRefillDate].filter(Boolean))] // Eliminar duplicados usando Set
            : newBlackRefillDate ? [newBlackRefillDate] : [];

        const updatedColorRefillDates = Array.isArray(selectedPrinter.color_refill_dates)
            ? [...new Set([...selectedPrinter.color_refill_dates, newColorRefillDate].filter(Boolean))] // Eliminar duplicados usando Set
            : newColorRefillDate ? [newColorRefillDate] : [];

        console.log('updatedBlackRefillDates:', updatedBlackRefillDates);
        console.log('updatedColorRefillDates:', updatedColorRefillDates);


        // Validación de precio
        let priceValue = null;
        if (selectedPrinter.price !== "" && selectedPrinter.price !== null && selectedPrinter.price !== undefined) {
            priceValue = parseFloat(selectedPrinter.price);
            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Validaciones de disponibilidad y estado
        if (selectedPrinter.available === 'true') {
            if (selectedPrinter.status !== 'inv') {
                setStatusError('If the Printer is available, its status must be "Inventario".');
                return;
            }
            if (selectedPrinter.assigned_to !== '' && selectedPrinter.assigned_to !== 'n/a') {
                setStatusError('If the Printer is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                return;
            }
        } else if (selectedPrinter.available === 'false') {
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedPrinter.status)) {
                setStatusError('If the Printer is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                return;
            }
            setStatusError('');
        }

        // Validacion de que si selecciono la Ink debe escoger la date
        if (selectedSupplyBlack && !newBlackRefillDate) {
            setRefillBlackError("Please select a refill date for the selected Black Ink.");
            return; // Detener si falta la fecha
        }
        if (selectedSupplyColor && !newColorRefillDate) {
            setRefillColorError("Please select a refill date for the selected Color Ink.");
            return; // Detener si falta la fecha
        }

        // Crear un array con los IDs de supplies seleccionados
        const selectedSupplies = [];
        if (selectedSupplyBlack) selectedSupplies.push(selectedSupplyBlack);
        if (selectedSupplyColor) selectedSupplies.push(selectedSupplyColor);

        // Si no hay supplies seleccionados, mostrar un mensaje y continuar
        if (selectedSupplies.length === 0) {
            console.warn("No supplies selected. Continuing with printer update only.");
        } else {
            try {
                // Actualizar los supplies seleccionados en la base de datos
                const updatePromises = selectedSupplies.map((supplyId) => {
                    return updSupply({
                        variables: {
                            input: {
                                id: supplyId,
                                available: false, // Cambiar estado a NO
                                assigned_to: selectedPrinter.assigned_to,
                                assignment_date: newBlackRefillDate, // Usar el valor de assigned_to de la impresora
                                location: selectedPrinter.location, //
                                team: selectedPrinter.team, //
                            },
                        },
                    });
                });
                // Esperar a que todas las actualizaciones se completen
                await Promise.all(updatePromises);
                alert("The Ink/Inks have been deducted from the Supplies inventory!");
                console.log("Supplies updated successfully:", selectedSupplies);
            } catch (error) {
                console.error("Error updating supplies:", error);
            }
        }
        // Resetear los select después de actualizar
        setSelectedSupplyBlack("");
        setSelectedSupplyColor("");

        // Agrega un console.log para verificar los datos antes de la mutación
        console.log("updatedBlackRefillDates:", updatedBlackRefillDates);
        console.log("updatedColorRefillDates:", updatedColorRefillDates);

        // Mutación para actualizar la impresora
        try {
            await updPrinter({
                variables: {
                    input: {
                        id: selectedPrinter.id,
                        tagid: selectedPrinter.tagid,
                        model: selectedPrinter.model,
                        brand: selectedPrinter.brand,
                        serial_Number: selectedPrinter.serial_Number,
                        price: priceValue,
                        store: selectedPrinter.store,
                        purchase_date: formattedData_purchase,
                        charger_cable: selectedPrinter.charger_cable,
                        Connector_cable: selectedPrinter.Connector_cable,
                        wireless: selectedPrinter.wireless,
                        black_ink: selectedPrinter.black_ink,
                        color_ink: selectedPrinter.color_ink,
                        black_refill_dates: updatedBlackRefillDates,  // Lista con todas las fechas
                        color_refill_dates: updatedColorRefillDates,
                        assignment_date: formattedData_assignment,
                        warranty_date: formattedData_warranty,
                        returnable_date: formattedData_returnable,
                        available: selectedPrinter.available,
                        status: selectedPrinter.status,
                        assigned_to: selectedPrinter.assigned_to,
                        location: selectedPrinter.location,
                        team: selectedPrinter.team,
                    },
                },
            });

            console.log("Printer updated successfully.");
            console.log("Valor final de printer.charger_cable en renderizado:", selectedPrinter.charger_cable);
            setShowPrinterAddOverlay(false);
            setPriceError('');
            setStatusError('');
            setDuplicateError('');
            setNewBlackRefillDate('');
            setNewColorRefillDate('');
            setRefillBlackError('');
            setRefillColorError('');

        } catch (error) {
            console.error("Error updating supplies or printer:", error);
        }
    };


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;


    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };


    return (
        <div className="printer-page">
            <header className="inventory-header">
                <h1>Printer</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>

            </div>

            <div className="searchBar">
                <button className="add-printer-btn" onClick={() => {
                    clearNewPrinter();
                    setSelectedPrinter(null);
                    setIsEditing(false);
                    setShowPrinterAddOverlay(true);
                }}>+ Add New Printer</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} /></div>

            <div className="printer-table-container">
                <table className="printer-table">
                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('Connector_cable')}>
                                Connector Cable
                                {sortConfig?.key === 'Connector_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('wireless')}>
                                Wireless
                                {sortConfig?.key === 'wireless' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('black_ink')}>
                                Black Ink Type
                                {sortConfig?.key === 'black_ink' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('black_refill_dates:[AWSDate]')}>
                                Blank Ink Refill Dates
                                {sortConfig?.key === 'black_refill_dates:[AWSDate]' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('color_ink')}>
                                Color Ink Type
                                {sortConfig?.key === ' color_ink' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('color_refill_dates:[AWSDate]')}>
                                Color Ink Refill Dates
                                {sortConfig?.key === 'color_refill_dates:[AWSDate]' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredPrinters.map((printer, index) => (
                            <tr key={printer.id} onClick={() => handleEditPrinter(printer)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="printer-tagid">{capitalize(printer.tagid)}</td>
                                <td className="printer-model">{capitalize(printer.model.toLowerCase())}</td>
                                <td className="printer-brand">{capitalize(printer.brand.toLowerCase())}</td>
                                <td className="printer-serial_Number">{printer.serial_Number.toUpperCase()}</td>
                                <td className="printer-price">${formatPrice(printer.price)}</td>
                                <td className="printer-store">{printer.store}</td>
                                <td className="printer-purchase_date">{printer.purchase_date ? formatDate(printer.purchase_date) : 'No Date'}</td>
                                <td className="printer-charger_cable">{printer.charger_cable ? 'Yes' : 'No'}</td>
                                <td className="printer-connector_cable">
                                    {
                                        {
                                            'HDMI': 'HDMI Cable',
                                            'HDMI2.0': 'HDMI 2.0 Cable',
                                            'HDMI2.1': 'HDMI 2.1 Cable',
                                            'C-C': 'C-C',
                                            'C-USB': 'C-USB Cable',
                                        }[printer.Connector_cable] || 'Other'
                                    }
                                </td>
                                <td className="printer-wireless">{printer.wireless ? 'Yes' : 'No'}</td>
                                <td className="printer-black_ink">{printer.black_ink}</td>
                                <td className="printer.black_refill_dates">
                                    {Array.isArray(printer.black_refill_dates) && printer.black_refill_dates.length > 0 ? (
                                        <ul className="black-refill-list">
                                            {printer.black_refill_dates.map((date, index) => {
                                                // Convertir la fecha a DD/MM/YYYY
                                                const formattedDate = new Intl.DateTimeFormat("en-US", {
                                                    timeZone: "UTC", // Evitar desfase de fechas
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    year: "numeric",
                                                }).format(new Date(date));
                                                return (
                                                    <li key={index} className="black-refill-item">{formattedDate}</li> // Mostrar la fecha formateada
                                                );
                                            })}
                                        </ul>
                                    ) : (
                                        <p>No refill dates available</p>
                                    )}
                                </td>


                                <td className="printer-color_ink">{printer.color_ink}</td>
                                <td className="printer.color_refill_dates">
                                    {Array.isArray(printer.color_refill_dates) && printer.color_refill_dates.length > 0 ? (
                                        <ul className="color-refill-list">
                                            {printer.color_refill_dates.map((date, index) => {
                                                // Convertir la fecha a DD/MM/YYYY
                                                const formattedDate = new Intl.DateTimeFormat("en-US", {
                                                    timeZone: "UTC", // Evitar desfase de fechas
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    year: "numeric",
                                                }).format(new Date(date));
                                                return (
                                                    <li key={index} className="color-refill-item">{formattedDate}</li> // Mostrar la fecha formateada
                                                );
                                            })}
                                        </ul>
                                    ) : (
                                        <p>No refill dates available</p>
                                    )}
                                </td>
                                <td className="printer-assignment_date">{printer.assignment_date ? formatDate(printer.assignment_date) : 'No Date'}</td>
                                <td className="printer-warranty_date">{printer.warranty_date ? formatDate(printer.warranty_date) : 'No Date'}</td>
                                <td className="printer-returnable_date">{printer.returnable_date ? formatDate(printer.returnable_date) : 'No Date'}</td>
                                <td className="printer-available">{printer.available ? 'Yes' : 'No'} </td>
                                <td className="printer-status">
                                    {printer.status === 'broken' ? 'Broken' :
                                        printer.status === 'use' ? 'Use' :
                                            printer.status === 'transfer' ? 'Transfer' :
                                                printer.status === 'lost' ? 'Lost' :
                                                    printer.status === 'inv' ? 'Inventario' :
                                                        'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="printer-assigned_to">{printer.assigned_to}</td>
                                <td className="printer-location">{printer.location}</td>
                                <td className="printer-team">{printer.team}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            {
                nextToken && !loading && (
                    <button onClick={loadMore}>Load More</button>
                )
            }


            {loading && <p>Loading...</p>}

            {
                showPrinterAddOverlay && (

                    <div className="printer_add_overlay" onClick={(e) => {
                        // Close overlay only if clicked outside the modal content
                        if (e.target.classList.contains("printer_add_overlay")) {
                            setShowPrinterAddOverlay(false);
                            setIsEditing(false);
                            setSelectedPrinter(null);
                            clearNewPrinter();
                        }
                    }}>
                        <div className="overlay-content-inventory">
                            <div className="header-row">
                                <h2 className="header-title">{isEditing ? 'Edit Printer' : 'Add New Printer'}</h2>
                                <button onClick={() => {
                                    setShowPrinterAddOverlay(false);
                                    clearNewPrinter();
                                    setSelectedPrinter(null);
                                    setIsEditing(false);
                                }}>X</button>
                            </div>

                            <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedPrinter.tagid : newPrinter.tagid} onChange={handleInputChange} required />
                            <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedPrinter.model : newPrinter.model} onChange={handleInputChange} required />

                            <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedPrinter.brand : newPrinter.brand} onChange={handleInputChange} required />
                            <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedPrinter.serial_Number : newPrinter.serial_Number} onChange={handleInputChange} required />

                            <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedPrinter.price : newPrinter.price} onChange={handleInputChange} />
                            <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedPrinter.store : newPrinter.store} onChange={handleInputChange} />

                            <label htmlFor="purchase_date">Purchase Date:</label>
                            <input
                                type="Date"
                                id="purchase_date"
                                name="purchase_date"
                                value={isEditing ? selectedPrinter.purchase_date : newPrinter.purchase_date}
                                onChange={handleInputChange}

                            />
                            <label>Charger Cable:</label>

                            <select name="charger_cable" placeholder="charger_cable" value={isEditing ? selectedPrinter.charger_cable : newPrinter.charger_cable} onChange={handleInputChange} >
                                <option value="" disabled hidden className="placeholder-option">Select</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>

                            <label>Connector Cable:</label>
                            <select name="Connector_cable" placeholder="connector_cable" value={isEditing ? selectedPrinter.Connector_cable : newPrinter.Connector_cable} onChange={handleInputChange} >
                                <option value="" disabled hidden>Select Connector Cable Type...</option>
                                <option value="HDMI">HDMI</option>
                                <option value="HDMI2.0">HDMI 2.0</option>
                                <option value="HDMI2.1">HDMI 2.1</option>
                                <option value="C-C">C-C</option>
                                <option value="C-USB">C-USB</option>
                                <option value="Other">Other</option>
                            </select>

                            <label>Wireless:</label>

                            <select name="wireless" placeholder="wireless" value={isEditing ? selectedPrinter.wireless : newPrinter.wireless} onChange={handleInputChange}>
                                <option value="" disabled hidden className="placeholder-option">Select Wireless</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>

                            </select>

                            <label> Black Ink Type:</label><input name="black_ink" placeholder=" black_ink" value={isEditing ? selectedPrinter.black_ink : newPrinter.black_ink} onChange={handleInputChange} />

                            {loading2 && <p>Loading supplies...</p>}
                            {userError2 && <p>Error loading supplies: {userError2.message}</p>}

                            {!loading2 && !userError2 && (
                                <>
                                    <label htmlFor="filtered-supplies">Select Black Ink type available:</label>
                                    <select
                                        id="filtered-supplies"
                                        value={selectedSupplyBlack || ""}
                                        onChange={(e) => setSelectedSupplyBlack(e.target.value)}
                                    >
                                        <option value="" disabled hidden>Select Ink</option>
                                        {filteredBlackSupplies.length > 0 ? (
                                            filteredBlackSupplies.map((supply) => (
                                                <option key={supply.id} value={supply.id}>
                                                    {supply.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="" disabled>No ink supplies available</option>
                                        )}
                                    </select>
                                </>
                            )}

                            <label>Black Ink Refill Date:</label>
                            <input
                                type="date"
                                id="black_refill_dates"
                                name="black_refill_dates"
                                value={newBlackRefillDate || ""}  // Usa el valor temporal
                                onBlur={(e) => handleDateChange('black_refill_dates', e.target.value)}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (!value) {
                                        console.log("No date selected");
                                        setNewBlackRefillDate(""); // Reinicia el estado si no hay fecha seleccionada
                                    } else {
                                        console.log("Selected date:", value);
                                        setNewBlackRefillDate(value); // Asigna la nueva fecha
                                    }
                                }}
                            />
                            <ul className="black-refill-list">
                                {selectedPrinter?.black_refill_dates?.length > 0 ? (
                                    selectedPrinter.black_refill_dates
                                        .slice() // Crear una copia del array para no mutar el original
                                        .sort((a, b) => new Date(a) - new Date(b)) // Ordenar las fechas en orden ascendente
                                        .map((date, index) => {
                                            // Formatear la fecha a MM/DD/YYYY
                                            const formattedDate = new Intl.DateTimeFormat("en-US", {
                                                timeZone: "UTC", // Evitar desfase de fechas
                                                month: "2-digit",
                                                day: "2-digit",
                                                year: "numeric",
                                            }).format(new Date(date));


                                            return (
                                                <li key={index} className="black-refill-item">
                                                    {formattedDate}
                                                    <button
                                                        className="delete-date-button"
                                                        onClick={() => handleDeleteDate(index)}
                                                        disabled={isUpdating} // Deshabilita el botón mientras se actualiza
                                                    >
                                                        Delete
                                                    </button>
                                                </li>
                                            );
                                        })
                                ) : (
                                    <p>No black refill dates available.</p>
                                )}
                            </ul>

                            <label> Color Ink Type:</label><input name="color_ink" placeholder="color_ink" value={isEditing ? selectedPrinter.color_ink : newPrinter.color_ink} onChange={handleInputChange} />

                            {loading2 && <p>Loading supplies...</p>}
                            {userError2 && <p>Error loading supplies: {userError2.message}</p>}

                            {!loading2 && !userError2 && (
                                <>
                                    <label htmlFor="filtered-supplies">Select Color Ink type available:</label>
                                    <select
                                        id="filtered-supplies"
                                        value={selectedSupplyColor || ""}
                                        onChange={(e) => setSelectedSupplyColor(e.target.value)}
                                    >
                                        <option value="" disabled hidden>Select Ink</option>
                                        {filteredColorSupplies.length > 0 ? (
                                            filteredColorSupplies.map((supply) => (
                                                <option key={supply.id} value={supply.id}>
                                                    {supply.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="" disabled>No ink supplies available</option>
                                        )}
                                    </select>
                                </>
                            )}
                            <label>Color Ink Refill Date:</label>
                            <input
                                type="date"
                                id="color_refill_dates"
                                name="color_refill_dates"
                                value={newColorRefillDate}  // Usa el valor temporal
                                onBlur={(e) => handleDateChange('color_refill_dates', e.target.value)}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (!value) {
                                        console.log("No date selected");
                                        setNewColorRefillDate(""); // Reinicia el estado si no hay fecha seleccionada
                                    } else {
                                        console.log("Selected date:", value);
                                        setNewColorRefillDate(value); // Asigna la nueva fecha
                                    }
                                }}
                            />

                            {/* Mostrar las fechas agregadas */}
                            <ul className="color-refill-list">
                                {selectedPrinter?.color_refill_dates?.length > 0 ? (
                                    selectedPrinter.color_refill_dates
                                        .slice() // Crear una copia del array para no mutar el original
                                        .sort((a, b) => new Date(a) - new Date(b)) // Ordenar las fechas en orden ascendente
                                        .map((date, index) => {
                                            // Formatear la fecha a MM/DD/YYYY
                                            const formattedDate = new Intl.DateTimeFormat("en-US", {
                                                timeZone: "UTC", // Evitar desfase de fechas
                                                month: "2-digit",
                                                day: "2-digit",
                                                year: "numeric",
                                            }).format(new Date(date));

                                            return (
                                                <li key={index} className="color-refill-item">
                                                    {formattedDate}
                                                    <button
                                                        className="delete-date-button"
                                                        onClick={() => handleDeleteColorDate(index)}
                                                        disabled={isUpdating} // Deshabilita el botón mientras se actualiza
                                                    >
                                                        Delete
                                                    </button>
                                                </li>
                                            );
                                        })
                                ) : (
                                    <p>No color refill dates available.</p>
                                )}
                            </ul>

                            <label htmlFor="assignment_date">Assignment Date:</label>
                            <input
                                type="date"
                                id="assignment_date"
                                name="assignment_date"
                                value={isEditing ? selectedPrinter.assignment_date : newPrinter.assignment_date}
                                onChange={handleInputChange}

                            />
                            <label htmlFor="warranty_date">Warranty Date:</label>
                            <input
                                type="date"
                                id="warranty_date"
                                name="warranty_date"
                                value={isEditing ? selectedPrinter.warranty_date : newPrinter.warranty_date}
                                onChange={handleInputChange}


                            />
                            <label htmlFor="returnable_date">Returnable Date:</label>
                            <input
                                type="date"
                                id="returnable_date"
                                name="returnable_date"
                                value={isEditing ? selectedPrinter.returnable_date : newPrinter.returnable_date}
                                onChange={handleInputChange}

                            />

                            <label>Available:</label>

                            <select name="available" placeholder="available" value={isEditing ? selectedPrinter.available : newPrinter.available} onChange={handleInputChange} >
                                <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>

                            </select>
                            <label>Status:</label>
                            <select name="status" placeholder="status" value={isEditing ? selectedPrinter.status : newPrinter.status} onChange={handleInputChange} >
                                <option value="" disabled hidden>Select Status</option>
                                <option value="use">Use</option>
                                <option value="broken">Broken</option>
                                <option value="transfer">Transfer</option>
                                <option value="lost">Lost</option>
                                <option value="inv">Inventario</option>

                            </select>
                            <label>Assigned To:</label>
                            <select
                                name="assigned_to"
                                value={isEditing ? selectedPrinter.assigned_to : newPrinter.assigned_to}
                                onChange={handleInputChange}
                            >
                                <option value="">Select an Employee</option>

                                {users.length > 0 ? (
                                    [...users].sort((a, b) => {
                                        const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                        const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                        return nameA.localeCompare(nameB);
                                    }).map(user => (
                                        <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                            {capitalize(user.first_name)} {capitalize(user.last_name)}
                                        </option>
                                    ))
                                ) : (

                                    <option value="">No users available</option>
                                )}
                                <option value='n/a'>N/A</option>
                            </select>

                            <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedPrinter.location : newPrinter.location} onChange={handleInputChange} />
                            <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedPrinter.team : newPrinter.team} onChange={handleInputChange} />

                            {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                            {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                            {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}
                            {refillBlackError && <p className="error">{refillBlackError}</p>} {/* Display Refill Ink Black error message */}
                            {refillColorError && <p className="error">{refillColorError}</p>} {/* Display Refill Ink Color error message */}

                            <div className="button-group">
                                <button className="overlay-add-printer" onClick={isEditing ? handleUpdatePrinter : handleAddPrinter} >
                                    {isEditing ? 'Update Printer' : 'Add Printer'}
                                </button>
                                {isEditing && (
                                    <button
                                        className="overlay-delet-printer"
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this printer?')) {
                                                handleDeletePrinter();
                                            }
                                        }}
                                    >
                                        Delete Printer
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }

        </div >


    );

};

export default Printer;

import React, { useState, useEffect, useRef } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { capitalize } from '../../../components/common/capitalize';
import dayjs from 'dayjs';
import './ShiftModal.css';

const ShiftModal = ({
    isOpen,
    onClose,
    onSave,
    onSaveTemplateShifts,
    shift,
    locations,
    navigators,
    onDelete,
    onUpdate,
    templates,
}) => {
    const [navigatorId, setNavigatorId] = useState('');
    const [startTime, setStartTime] = useState('08:00'); // Default start time
    const [endTime, setEndTime] = useState('16:00'); // Default end time
    const [breakStart, setBreakStart] = useState('12:00'); // Default break start time
    const [location, setLocation] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [activeTab, setActiveTab] = useState('singleShift'); // "singleShift" or "multiShift"
    const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD')); // Default to current date
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [userGroup, setUserGroup] = useState(null);
    const modalRef = useRef(null);

    useEffect(() => {
        // Fetch the user's group when the component mounts
        const fetchUserGroup = async () => {
            try {
                const session = await fetchAuthSession();
                const groups = session?.tokens?.accessToken?.payload['cognito:groups'] || [];
                setUserGroup(groups);
            } catch (error) {
                console.error('Error fetching user groups:', error);
                setUserGroup([]);
            }
        };

        fetchUserGroup();
    }, []);

    useEffect(() => {
        if (!shift) {
            // Clear the state if no shift is selected
            setNavigatorId('');
            setStartTime('08:00');
            setEndTime('16:00');
            setBreakStart('12:00');
            setLocation('');
            setIsEditing(false);
            return;
        }

        // Populate the state with shift details
        setNavigatorId(shift.userId || '');
        setStartTime(shift.startTime || '08:00');
        setEndTime(shift.endTime || '16:00');
        setBreakStart(shift.breakStart || '12:00');
        setLocation(shift.location || '');
        setIsEditing(true);
    }, [shift]);

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('shift-modal-overlay')) {
            onClose();
        }
    };

    const handleSubmit = () => {
        if (!navigatorId || !startTime || !endTime || !location || !breakStart) {
            alert('Please fill in all fields.');
            return;
        }

        const newShift = {
            id: shift ? shift.id : `shift-${Date.now()}`,
            date: dayjs(startDate).format('YYYY-MM-DD'),
            startTime,
            endTime,
            breakStart,
            breakEnd: dayjs(`${startDate}T${breakStart}`).add(1, 'hour').format('HH:mm'),
            title: `Shift for Navigator ${navigatorId}`,
            subtitle: locations.find((loc) => loc.id === location)?.name || 'Location',
            bgColor: locations.find((loc) => loc.id === location)?.color || '#3174ad',
            location,
            resourceId: navigatorId,
        };

        onSave(newShift);
    };

    const handleUpdate = () => {
        const updatedShift = {
            id: shift ? shift.id : `shift-${Date.now()}`,
            date: dayjs(startDate).format('YYYY-MM-DD'),
            startTime,
            endTime,
            breakStart,
            breakEnd: dayjs(`${startDate}T${breakStart}`).add(1, 'hour').format('HH:mm'),
            title: `Shift for Navigator ${navigatorId}`,
            subtitle: locations.find((loc) => loc.id === location)?.name || 'Location',
            bgColor: locations.find((loc) => loc.id === location)?.color || '#3174ad',
            location,
            resourceId: navigatorId,
        };

        onUpdate(updatedShift);
    };

    const handleMultiShiftSubmit = () => {
        if (!navigatorId || !startDate || !selectedTemplate) {
            alert('Please select a navigator, start date, and template.');
            return;
        }

        const template = templates.find((t) => t.id === selectedTemplate);

        if (!template) {
            alert('Selected template not found.');
            return;
        }

        onSaveTemplateShifts(navigatorId, startDate, template, location);
        onClose();
    };

    const isEditable = (userGroup) =>
        Array.isArray(userGroup) && userGroup.some((group) => ['Admin', 'Manager'].includes(group));

    const editable = isEditable(userGroup);

    if (!isOpen) return null;

    return (
        <div className="shift-modal-overlay" onClick={handleOverlayClick}>
            <div className="shift-modal" ref={modalRef}>
                <div className="shift-modal-tabs">
                    <button
                        className={activeTab === 'singleShift' ? 'active' : ''}
                        onClick={() => setActiveTab('singleShift')}
                    >
                        Single Shift
                    </button>
                    <button
                        className={activeTab === 'multiShift' ? 'active' : ''}
                        onClick={() => setActiveTab('multiShift')}
                    >
                        Multi Shift
                    </button>
                </div>

                {activeTab === 'singleShift' && (
                    <div className="tab-content">
                        <h3>{isEditing ? 'Edit Shift' : 'Create Shift'}</h3>
                        <div className="modal-row">
                            <label htmlFor="navigator-select">Navigator</label>
                            <select
                                id="navigator-select"
                                value={navigatorId}
                                onChange={(e) => setNavigatorId(e.target.value)}
                                disabled={!editable}
                            >
                                <option value="" disabled>
                                    Select Navigator
                                </option>
                                {navigators
                                    .slice()
                                    .sort((a, b) =>
                                        `${a.first_name} ${a.last_name}`.localeCompare(
                                            `${b.first_name} ${b.last_name}`
                                        )
                                    )
                                    .map((nav) => (
                                        <option key={nav.id} value={nav.id}>
                                            {`${capitalize(nav.first_name)} ${capitalize(nav.last_name)}`}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <div className="modal-row modal-row-split">
    <div>
        <label htmlFor="start-time-picker">Start DateTime</label>
        <input
            type="datetime-local"
            id="start-time-picker"
            value={`${dayjs(startDate).format('YYYY-MM-DD')}T${startTime}`}
            onChange={(e) => {
                const [date, time] = e.target.value.split('T');
                setStartDate(date); // Update the date
                setStartTime(time); // Update the time
            }}
            disabled={!editable}
        />
    </div>
    <div>
        <label htmlFor="end-time-picker">End DateTime</label>
        <input
            type="datetime-local"
            id="end-time-picker"
            value={`${dayjs(startDate).format('YYYY-MM-DD')}T${endTime}`}
            onChange={(e) => {
                const [date, time] = e.target.value.split('T');
                setStartDate(date); // Update the date (same for start and end)
                setEndTime(time); // Update the time
            }}
            disabled={!editable}
        />
    </div>
</div>

                        <div className="modal-row modal-row-split">
                            <div>
                                <label htmlFor="break-start-picker">Break Start</label>
                                <input
                                    type="time"
                                    id="break-start-picker"
                                    value={breakStart}
                                    onChange={(e) => setBreakStart(e.target.value)}
                                    disabled={!editable}
                                />
                            </div>
                            <div>
                                <label htmlFor="location-select">Location</label>
                                <select
                                    id="location-select"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                    <option value="" disabled>
                                        Select Location
                                    </option>
                                    {locations.map((loc) => (
                                        <option key={loc.id} value={loc.name}>
                                            {loc.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="modal-row modal-buttons">
                            {!isEditing && <button onClick={handleSubmit}>Save</button>}
                            {editable && isEditing && (
                                <>
                                    <button onClick={handleUpdate}>Update</button>
                                    <button onClick={onDelete}>Delete</button>
                                </>
                            )}
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                )}

                {activeTab === 'multiShift' && (
                    <div className="tab-content">
                        <h3>Create Multi Shift with Template</h3>

                        {/* Navigator Selector */}
                        <div className="modal-row">
                            <label htmlFor="navigator-select">Navigator</label>
                            <select
                                id="navigator-select"
                                value={navigatorId}
                                onChange={(e) => setNavigatorId(e.target.value)}
                            >
                                <option value="" disabled>
                                    Select Navigator
                                </option>
                                {navigators.map((nav) => (
                                    <option key={nav.id} value={nav.id}>
                                        {`${capitalize(nav.first_name)} ${capitalize(nav.last_name)}`}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Start Date Picker */}
                        <div className="modal-row">
                            <label htmlFor="start-date-picker">Start Date</label>
                            <input
                                type="date"
                                id="start-date-picker"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>

                        {/* Template Picker */}
                        <div className="modal-row">
                            <label htmlFor="template-select">Template</label>
                            <select
                                id="template-select"
                                value={selectedTemplate}
                                onChange={(e) => setSelectedTemplate(e.target.value)}
                            >
                                <option value="" disabled>
                                    Select Template
                                </option>
                                {templates.map((template) => (
                                    <option key={template.id} value={template.id}>
                                        {template.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Location Picker */}
                        <div className="modal-row">
                            <label htmlFor="location-select">Location</label>
                            <select
                                id="location-select"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            >
                                <option value="" disabled>
                                    Select Location
                                </option>
                                {locations.map((loc) => (
                                    <option key={loc.id} value={loc.id}>
                                        {loc.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Save Button */}
                        <div className="modal-buttons">
                            <button onClick={handleMultiShiftSubmit}>Save Shifts</button>
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default ShiftModal;

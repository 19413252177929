import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DashboardListReports } from '../../../graphql/queries';
import { Bar } from 'react-chartjs-2';
import './Rank_Dashboard.css';

const RankDashboard = () => {
    const [navigatorData, setNavigatorData] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedNavigators, setSelectedNavigators] = useState([]);
    const [fetchReports] = useLazyQuery(DashboardListReports);

    // Define the date range for filtering
    const startDate = new Date('2024-08-27');
    const endDate = new Date('2025-08-29');

    useEffect(() => {
        loadNavigatorProductivity();
    }, []);

    const loadNavigatorProductivity = async () => {
        let allReports = [];
        let nextToken = null;
        let isLoading = true;

        try {
            while (isLoading) {
                const { data } = await fetchReports({
                    variables: {
                        limit: 50,
                        nextToken: nextToken,
                    },
                });

                if (data && data.listReports && data.listReports.items) {
                    allReports = [...allReports, ...data.listReports.items];
                    nextToken = data.listReports.nextToken;
                }

                isLoading = !!nextToken;
            }

            processNavigatorData(allReports);
        } catch (error) {
            console.error("Error fetching reports:", error);
        } finally {
            setLoading(false);
        }
    };

    const processNavigatorData = (reports) => {
        const navigatorProductivity = {};

        reports.forEach(report => {
            // Convert report date to a Date object
            const reportDate = new Date(report.Date);

            // Only include reports within the date range
            if (reportDate >= startDate && reportDate <= endDate) {
                const navigator = report.Navigator;

                if (!navigatorProductivity[navigator]) {
                    navigatorProductivity[navigator] = { QHP: 0, Medicaid: 0 };
                }

                // Sum QHP enrollments
                const qhpFields = ['qhp_enrollment_hcgov', 'elctronic_qhp_enrollment_marketplaceCallCenter', 'written_qhp_enrollment_hcgov'];
                navigatorProductivity[navigator].QHP += qhpFields.reduce((sum, field) => {
                    return sum + (parseFloat(report[field]) || 0);
                }, 0);

                // Sum Medicaid enrollments
                navigatorProductivity[navigator].Medicaid += parseFloat(report.medicaid_chip_app_ref) || 0;
            }
        });

        setNavigatorData(navigatorProductivity);
        setSelectedNavigators(Object.keys(navigatorProductivity)); // Initialize selectedNavigators
    };

    const sortedNavigators = Object.entries(navigatorData)
        .sort(([, a], [, b]) => b.QHP - a.QHP || b.Medicaid - a.Medicaid);

    // Sorting by QHP for the QHP chart
    const sortedNavigatorsByQHP = Object.entries(navigatorData).sort(([, a], [, b]) => b.QHP - a.QHP);

    // Sorting by Medicaid for the Medicaid chart
    const sortedNavigatorsByMedicaid = Object.entries(navigatorData).sort(([, a], [, b]) => b.Medicaid - a.Medicaid);

    // Filter based on selected navigators for QHP and Medicaid charts
    const filteredDataByQHP = sortedNavigatorsByQHP.filter(([navigator]) => selectedNavigators.includes(navigator));
    const filteredDataByMedicaid = sortedNavigatorsByMedicaid.filter(([navigator]) => selectedNavigators.includes(navigator));

    // Data for QHP chart
    const qhpChartData = {
        labels: filteredDataByQHP.map(([navigator]) => navigator),
        datasets: [
            {
                label: 'QHP Enrollments',
                data: filteredDataByQHP.map(([, data]) => data.QHP),
                backgroundColor: 'rgba(142, 212, 212, 0.6)',
            }
        ]
    };

    // Data for Medicaid chart
    const medicaidChartData = {
        labels: filteredDataByMedicaid.map(([navigator]) => navigator),
        datasets: [
            {
                label: 'Medicaid Enrollments',
                data: filteredDataByMedicaid.map(([, data]) => data.Medicaid),
                backgroundColor: 'rgba(189, 158, 250, 0.6)',
            }
        ]
    };


    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    // Toggle navigator selection
    const toggleNavigator = (navigator) => {
        setSelectedNavigators((prevSelected) =>
            prevSelected.includes(navigator)
                ? prevSelected.filter((nav) => nav !== navigator)
                : [...prevSelected, navigator]
        );
    };

    return (
        <div className="rank-dashboard">
            <header className="rank-dashboard-header">
                <h1>Navigator Productivity Dashboard</h1>
            </header>
            {loading ? (
                <p>Loading data...</p>
            ) : (
                <>
                    {/* Navigator selection checkboxes */}
                    <div className="navigator-selection">
                        {Object.entries(navigatorData)
                            .sort(([aName], [bName]) => aName.localeCompare(bName)) // Sort navigators alphabetically by name
                            .map(([navigator]) => (
                                <label key={navigator}>
                                    <input
                                        type="checkbox"
                                        checked={selectedNavigators.includes(navigator)}
                                        onChange={() => toggleNavigator(navigator)}
                                    />
                                    {navigator}
                                </label>
                            ))}
                    </div>

                    {/* QHP Chart */}
                    <div className="chart-container">
                        <h2>QHP Enrollments</h2>
                        <Bar data={qhpChartData} options={options} />
                    </div>

                    {/* Medicaid Chart */}
                    <div className="chart-container">
                        <h2>Medicaid Enrollments</h2>
                        <Bar data={medicaidChartData} options={options} />
                    </div>
                </>
            )}
        </div>
    );
};

export default RankDashboard;

import React, { useEffect } from 'react';
import { Scheduler, SchedulerData } from "@bitnoi.se/react-scheduler";
import '@bitnoi.se/react-scheduler/dist/style.css';
import '../styles/scheduler.css';

const ScheduleCalendar = ({
    calendarEvents,
    onItemClick,
    onUserClick,
}) => {
    useEffect(() => {
    }, [calendarEvents]);
    return (
        <div className="calendar-container">
            <Scheduler
                data={calendarEvents}
                onTileClick={(clickedTile) => {
                    onItemClick(clickedTile, true); // Pass the clicked tile and set editing to true
                }}
                onItemClick={(clickedUser) => {
                    onUserClick(clickedUser);
                }}
                config={{ 
                    zoom: 0, 
                    lang: 'en', 
                    maxRecordsPerPage: 35,
                    filterButtonState: -1,
                    showThemeToggle: true,
                    includeTakenHoursOnWeekendsInDayView: true,
                }}
            />
        </div>
    );
};

export default ScheduleCalendar;

import React, { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useLazyQuery, useMutation } from '@apollo/client'; // Import useMutation
import {
    listUsersByTeamOne, getWorkSchedulesByUser, getWorkSchedule,
    listScheduleTemplates, getScheduleTemplate, GET_ALL_LOCATIONS
} from '../../graphql/queries';
import {
    createWorkSchedule, deleteWorkSchedule, updateWorkSchedule
} from '../../graphql/mutations'; // Import the mutation
import ScheduleCalendar from './components/ScheduleCalendar';
import ShiftModal from './components/ShiftModal';
import TemplateModal from './components/TemplateModal';
import { capitalize } from '../../components/common/capitalize';
import ListLocations from '../../components/Locations/ListLocations';
import dayjs from 'dayjs';
import './WorkSchedules.css';
const WorkSchedules = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [teamOneNavigators, setTeamOneNavigators] = useState([]);
    const [isShiftModalOpen, setIsShiftModalOpen] = useState(false);
    const [selectedShift, setSelectedShift] = useState(null);
    const [userGroup, setUserGroup] = useState(null);
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false); // Manage Location Modal
    const [scheduleTemplates, setScheduleTemplates] = useState([]);
    const [locations, setLocations] = useState([]);
    const openLocationModal = () => setIsLocationModalOpen(true);
    const closeLocationModal = () => setIsLocationModalOpen(false);
    const [fetchUsers] = useLazyQuery(listUsersByTeamOne);
    const [fetchWorkSchedules] = useLazyQuery(getWorkSchedulesByUser);
    const [fetchWorkSchedule] = useLazyQuery(getWorkSchedule);
    const [saveWorkSchedule] = useMutation(createWorkSchedule); // Use mutation hook
    const [updateSchedule] = useMutation(updateWorkSchedule);
    const [removeWorkSchedule] = useMutation(deleteWorkSchedule);
    const [fetchTemplates] = useLazyQuery(listScheduleTemplates, {
        fetchPolicy: 'network-only', // Force fetch from server to get updated data
    });
    const refetchTemplates = async () => {
        try {
            const { data } = await fetchTemplates();
            if (data?.listScheduleTemplates?.items) {
                setScheduleTemplates(data.listScheduleTemplates.items);
            }
        } catch (error) {
            console.error('Error refetching templates:', error);
        }
    };
    const transformDataForScheduler = (users, schedules) => {
        // Define color mapping for locations
        const locationColors = {
            office: '#3174ad', // Office
            remote: '#f57f17', // Remote
            onsite: '#d9534f', // On-site
        };

        // Map users to their respective schedules
        return users.map((user) => {
            const userSchedules = schedules.filter((schedule) => schedule.resourceId === user.id);

            return {
                id: user.id, // Navigator/User ID
                label: {
                    title: `${capitalize(user.first_name)} ${capitalize(user.last_name)}`, // Navigator name
                    subtitle: 'Navigator', // Role or any other subtitle
                },
                data: userSchedules.flatMap((schedule) => {
                    const segments = [];
                    const { id, startDate, endDate, breakStart, breakEnd, location } = schedule;

                    // Validate and parse dates
                    const start = startDate ? new Date(startDate) : null;
                    const end = endDate ? new Date(endDate) : null;
                    const breakStartTime = breakStart ? new Date(breakStart) : null;
                    const breakEndTime = breakEnd ? new Date(breakEnd) : null;

                    // Ensure valid start and end times
                    if (!start || isNaN(start.getTime()) || !end || isNaN(end.getTime())) {
                        console.warn('Invalid schedule times:', schedule);
                        return []; // Skip invalid schedules
                    }

                    // Work before break
                    if (breakStartTime && !isNaN(breakStartTime.getTime())) {
                        segments.push({
                            id: `${id}-beforeBreak`,
                            startDate: start.toISOString(),
                            endDate: new Date(breakStartTime.getTime() - 60 * 1000).toISOString(),
                            title: `${location} Shift`,
                            subtitle: `${location} (Before Break)`,
                            description: `Work before break at ${location}`,
                            occupancy: Math.round((breakStartTime - start) / 1000),
                            bgColor: locationColors[location.toLowerCase()] || '#CCCCCC',
                        });
                    }

                    // Break time (optional)
                    if (breakStartTime && breakEndTime && !isNaN(breakEndTime.getTime())) {
                        segments.push({
                            id: `${id}-break`,
                            startDate: breakStartTime.toISOString(),
                            endDate: new Date(breakEndTime.getTime() - 60 * 1000).toISOString(),
                            title: `Break`,
                            subtitle: `Unpaid Break`,
                            description: `Break time at ${location}`,
                            occupancy: Math.round((breakEndTime - breakStartTime) / 1000),
                            bgColor: '#CCCCCC', // Neutral color for break
                        });
                    }

                    // Work after break
                    if (breakEndTime && !isNaN(breakEndTime.getTime())) {
                        segments.push({
                            id: `${id}-afterBreak`,
                            startDate: breakEndTime.toISOString(),
                            endDate: end.toISOString(),
                            title: `${location} Shift`,
                            subtitle: `${location} (After Break)`,
                            description: `Work after break at ${location}`,
                            occupancy: Math.round((end - breakEndTime) / 1000),
                            bgColor: locationColors[location.toLowerCase()] || '#CCCCCC',
                        });
                    } else {
                        // No break case: entire shift as one segment
                        segments.push({
                            id: `${id}-noBreak`,
                            startDate: start.toISOString(),
                            endDate: end.toISOString(),
                            title: `${location} Shift`,
                            subtitle: location,
                            description: `${schedule.title} at ${location}`,
                            occupancy: Math.round((end - start) / 1000),
                            bgColor: locationColors[location.toLowerCase()] || '#CCCCCC',
                        });
                    }

                    return segments;
                }),
            };
        });
    };

    useEffect(() => {
        // Fetch the user's group when the component mounts
        const fetchUserGroup = async () => {
            try {
                const session = await fetchAuthSession();
                const groups = session?.tokens?.accessToken?.payload["cognito:groups"] || []; // Safely access groups
                setUserGroup(groups); // Set the user's groups (array)
            } catch (error) {
                console.error('Error fetching user groups:', error);
                setUserGroup([]); // Fallback to an empty array if fetching fails
            }
        };

        fetchUserGroup();
    }, []);

    useEffect(() => {
        const loadSchedules = async () => {
            const { data: userData } = await fetchUsers({ variables: { limit: 50 } });
            if (userData && userData.listUsers) {
                // Sort users by name in alphabetical order
                const sortedUsers = userData.listUsers.items
                    .filter(user => user.first_name && user.last_name) // Ensure both first_name and last_name exist
                    .sort((a, b) => {
                        const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                        const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                        return nameA.localeCompare(nameB);
                    });


                const userIds = sortedUsers.map((user) => user.id);
                setTeamOneNavigators(sortedUsers);

                const allSchedules = await Promise.all(
                    userIds.map(async (userId) => {
                        const { data: scheduleData } = await fetchWorkSchedules({
                            variables: {
                                userId,
                                startDate: '2024-08-27',
                                endDate: '2025-08-29',
                            },
                        });

                        return (
                            scheduleData?.workSchedulesByUser?.items.map((schedule) => {
                                const startDate = new Date(`${schedule.date}T${schedule.startTime}`);
                                const endDate = new Date(`${schedule.date}T${schedule.endTime}`);
                                const BreakstartDate = new Date(`${schedule.date}T${schedule.breakStart}`);
                                const BreakendDate = new Date(`${schedule.date}T${schedule.breakEnd}`);

                                const formattedStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
                                    .toISOString()
                                    .slice(0, 19);

                                const formattedEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
                                    .toISOString()
                                    .slice(0, 19);

                                const formattedBreakStart = BreakstartDate
                                    ? new Date(BreakstartDate.getTime() - BreakstartDate.getTimezoneOffset() * 60000)
                                        .toISOString()
                                        .slice(0, 19)
                                    : null;

                                const formattedBreakEnd = BreakendDate
                                    ? new Date(BreakendDate.getTime() - BreakendDate.getTimezoneOffset() * 60000)
                                        .toISOString()
                                        .slice(0, 19)
                                    : null;

                                // Return the transformed schedule
                                return {
                                    id: schedule.id,
                                    title: `${schedule.location} Shift`,
                                    startDate: formattedStartDate, // Properly constructed startDate
                                    endDate: formattedEndDate, // Properly constructed endDate
                                    breakStart: formattedBreakStart, // Properly constructed breakStart
                                    breakEnd: formattedBreakEnd, // Properly constructed breakEnd
                                    resourceId: userId,
                                    location: schedule.location,
                                };
                            }) || []
                        );
                    })
                );

                // Combine all schedules and transform data for the scheduler
                const flatSchedules = allSchedules.flat();
                const transformedData = transformDataForScheduler(sortedUsers, flatSchedules); // Use sorted users
                setCalendarEvents(transformedData);
            }
        };

        loadSchedules();
    }, [fetchUsers, fetchWorkSchedules]);

    useEffect(() => {
        const loadTemplates = async () => {
            try {
                const { data } = await fetchTemplates();
                if (data?.listScheduleTemplates?.items) {
                    setScheduleTemplates(data.listScheduleTemplates.items);
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };

        loadTemplates();
    }, [fetchTemplates]);


    const handleAddShift = () => {
        setSelectedShift(null); // Reset selected shift for creating a new shift
        setIsShiftModalOpen(true);
    };

    const handleSaveTemplateShifts = async (navigatorId, startDate, template, location) => {
        if (!navigatorId || !startDate || !template) {
            console.error('Missing required parameters: navigatorId, startDate, or template.');
            return;
        }

        try {
            // Iterate through the template's days and format shifts
            const shifts = template.days.map((day, index) => {
                if (day.shift === 'OFF') return null; // Skip "OFF" days

                const [startTime, endTime] = day.shift.split('-');
                const date = dayjs(startDate).add(index, 'day').format('YYYY-MM-DD');

                console.log(location)
                return {
                    resourceId: navigatorId,
                    date,
                    startTime: `${startTime}:00`, // Ensure format matches "HH:mm:ss"
                    endTime: `${endTime}:00`,
                    breakStart: dayjs(`${date}T${startTime}`)
                        .add(4, 'hours') // Default break starts 4 hours after shift starts
                        .format('HH:mm:ss'),
                    breakEnd: dayjs(`${date}T${startTime}`)
                        .add(5, 'hours') // Default 1-hour break
                        .format('HH:mm:ss'),
                    location: location || 'office', // Default location; modify as needed
                };
            }).filter(Boolean); // Remove null values for "OFF" days
            // Upload each shift using handleSaveShift
            for (const shift of shifts) {
                await handleSaveShift(shift);
            }

            alert('Template shifts successfully saved!');
        } catch (error) {
            console.error('Error saving template shifts:', error);
            alert('An error occurred while saving template shifts. Please try again.');
        }
    };

    // Open the modal with the selected event
    const handleTileClick = async (item, isEditing = false) => {
        const cleanedId = item.id.replace(/-(noBreak|afterBreak|break|beforeBreak)$/, '');
        try {
            const { data } = await fetchWorkSchedule({
                variables: { id: cleanedId },
            });
            const schedule = data?.getWorkSchedule;
            if (schedule) {
                // Populate selectedShift with fetched schedule details
                setSelectedShift(schedule);
                setIsShiftModalOpen(true); // Open modal with fetched details
            }
            // Add "isEditing" flag to the shift data (optional, if needed in ShiftModal)
            if (isEditing) {
                item.isEditing = true;
            }
        } catch (error) {
            console.error('Error fetching work schedule by ID:', error);
        }
    };

    const handleUserClick = (clickedUser) => {
        console.log('User clicked:', clickedUser);
    };

    const handleSaveShift = async (newShift) => {
        try {
            // Format the data to match the WorkSchedule schema
            const input = {
                userId: newShift.resourceId,
                date: newShift.date, // AWSDate (YYYY-MM-DD)
                startTime: newShift.startTime, // AWSTime (HH:mm:ss)
                endTime: newShift.endTime, // AWSTime (HH:mm:ss)
                breakStart: newShift.breakStart, // AWSTime (HH:mm:ss)
                breakEnd: newShift.breakEnd, // AWSTime (HH:mm:ss)
                location: newShift.location,
            };

            // Upload data to DynamoDB
            const { data } = await saveWorkSchedule({ variables: { input } });

            // Construct the new event in the correct format
            const newEvent = {
                id: data.createWorkSchedule.id, // Use the ID from the created schedule
                title: `${input.location} Shift`,
                startDate: `${input.date}T${input.startTime}`, // Combine date and time
                endDate: `${input.date}T${input.endTime}`, // Combine date and time
                breakStart: input.breakStart ? `${input.date}T${input.breakStart}` : null, // Optional breakStart
                breakEnd: input.breakEnd ? `${input.date}T${input.breakEnd}` : null, // Optional breakEnd
                resourceId: input.userId,
                location: input.location,
            };

            setCalendarEvents((prevEvents) => {
                // Extract user and schedule data for transformation
                const newUser = {
                    id: newShift.resourceId,
                    first_name: teamOneNavigators.find(nav => nav.id === newShift.resourceId)?.first_name || '',
                    last_name: teamOneNavigators.find(nav => nav.id === newShift.resourceId)?.last_name || '',
                };

                const newSchedule = [
                    {
                        id: newEvent.id,
                        title: newEvent.title,
                        startDate: newEvent.startDate,
                        endDate: newEvent.endDate,
                        breakStart: newEvent.breakStart,
                        breakEnd: newEvent.breakEnd,
                        resourceId: newShift.resourceId,
                        location: newShift.location,
                    }
                ];

                // Transform the new event using the same logic as initial data loading
                const transformedData = transformDataForScheduler([newUser], newSchedule);
                console.log(transformedData)

                // Merge with previous events
                const mergedEvents = [...prevEvents];

                // Find if the user already exists in prevEvents
                const userIndex = mergedEvents.findIndex(event => event.id === newShift.resourceId);

                if (userIndex > -1) {
                    // User already exists, append to their data
                    mergedEvents[userIndex].data = [...mergedEvents[userIndex].data, ...transformedData[0].data];
                } else {
                    // User doesn't exist, add as a new entry
                    mergedEvents.push(transformedData[0]);
                }

                return mergedEvents;
            });
            setIsShiftModalOpen(false); // Close the modal
        } catch (error) {
            console.error('Error saving shift:', error);
        }
    };

    const handleUpdateShift = async (updatedShift) => {
        try {
            // Validate input
            if (
                !updatedShift.id ||
                !updatedShift.resourceId ||
                !updatedShift.date ||
                !updatedShift.startTime ||
                !updatedShift.endTime ||
                !updatedShift.location
            ) {
                console.error('Invalid updatedShift:', updatedShift);
                return;
            }

            // Format input for backend update
            const input = {
                id: updatedShift.id,
                userId: updatedShift.resourceId,
                date: updatedShift.date,
                startTime: updatedShift.startTime,
                endTime: updatedShift.endTime,
                breakStart: updatedShift.breakStart,
                breakEnd: updatedShift.breakEnd,
                location: updatedShift.location,
            };

            // Update in the backend
            await updateSchedule({ variables: { input } });

            // Transform the updated shift into the same structure as the scheduler expects
            const updatedUser = {
                id: updatedShift.resourceId,
                first_name: teamOneNavigators.find(nav => nav.id === updatedShift.resourceId)?.first_name || '',
                last_name: teamOneNavigators.find(nav => nav.id === updatedShift.resourceId)?.last_name || '',
            };

            const updatedSchedule = [
                {
                    id: updatedShift.id,
                    startDate: `${updatedShift.date}T${updatedShift.startTime}`,
                    endDate: `${updatedShift.date}T${updatedShift.endTime}`,
                    breakStart: updatedShift.breakStart
                        ? `${updatedShift.date}T${updatedShift.breakStart}`
                        : null,
                    breakEnd: updatedShift.breakEnd
                        ? `${updatedShift.date}T${updatedShift.breakEnd}`
                        : null,
                    resourceId: updatedShift.resourceId,
                    location: updatedShift.location,
                },
            ];

            // Transform updated data using the same logic as for initial loading
            const transformedData = transformDataForScheduler([updatedUser], updatedSchedule);

            // Update the state
            setCalendarEvents((prevEvents) => {
                return prevEvents.map((userEvent) => {
                    if (userEvent.id === updatedShift.resourceId) {
                        // Replace old events for this shift with the new transformed data
                        const filteredEvents = userEvent.data.filter(
                            (event) => !event.id.startsWith(updatedShift.id)
                        );
                        return {
                            ...userEvent,
                            data: [...filteredEvents, ...transformedData[0].data],
                        };
                    }
                    return userEvent; // No changes for other users
                });
            });

            setIsShiftModalOpen(false); // Close modal
        } catch (error) {
            console.error('Error updating schedule:', error);
        }
    };

    const handleDeleteShift = async (shiftId) => {
        try {
            // Remove any suffix from the ID to get the base shift ID
            const cleanedId = shiftId.replace(/-(noBreak|afterBreak|break|beforeBreak)$/, '');

            // Delete the schedule from the database
            await removeWorkSchedule({ variables: { input: { id: cleanedId } } });

            // Update calendarEvents to remove all events with the cleaned ID
            setCalendarEvents((prevEvents) =>
                prevEvents.map((userEvent) => {
                    // Filter out events where the id starts with the cleanedId
                    const updatedData = userEvent.data.filter((event) => !event.id.startsWith(cleanedId));

                    // Return the updated user entry with filtered data
                    return { ...userEvent, data: updatedData };
                })
            );

            setIsShiftModalOpen(false); // Close the modal
        } catch (error) {
            console.error('Error deleting shift:', error);
        }
    };

    // Fetch locations query
    const [fetchLocations] = useLazyQuery(GET_ALL_LOCATIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data?.listLocations?.items) {
                setLocations(data.listLocations.items);
            }
        },
        onError: (error) => {
            console.error("Error fetching locations:", error);
        },
    });

    useEffect(() => {
        fetchLocations(); // Fetch locations on mount
    }, [fetchLocations]);

    return (
        <div className="work-schedules-page">
            <header className="work-schedules-header">
                <h1>Work Schedules</h1>
            </header>
            <div className="controllers-work-schedule">
                {userGroup?.some(group => ["Admin", "Manager"].includes(group)) && (
                    <>
                        <button onClick={handleAddShift} className="add-shift-button">
                            Add New Shift
                        </button>
                        <button onClick={() => setIsTemplateModalOpen(true)} className="template-button">
                            Manage Templates
                        </button>
                        <button onClick={openLocationModal} className="manage-locations-button">
                            Manage Locations
                        </button>
                    </>
                )}
            </div>
            <ScheduleCalendar
                calendarEvents={calendarEvents}
                onItemClick={handleTileClick}
                onUserClick={handleUserClick}
            />
            <ShiftModal
                isOpen={isShiftModalOpen}
                onClose={() => setIsShiftModalOpen(false)}
                onSave={handleSaveShift}
                onSaveTemplateShifts={handleSaveTemplateShifts}
                onDelete={() => handleDeleteShift(selectedShift?.id)}
                onUpdate={handleUpdateShift}
                shift={selectedShift}
                locations={locations} // Pass locations here
                navigators={teamOneNavigators}
                templates={scheduleTemplates}
            />
            <TemplateModal
                isOpen={isTemplateModalOpen}
                onClose={() => setIsTemplateModalOpen(false)}
                templates={scheduleTemplates}
                refetchTemplates={refetchTemplates} // Add this
            />
            <ListLocations 
                isOpen={isLocationModalOpen} 
                onClose={closeLocationModal} 
            />
        </div>
    );
};

export default WorkSchedules;

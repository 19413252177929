import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listUsersByTeamOne } from '../../../graphql/queries';
import { UPDATE_CALL_LIST } from '../../../graphql/mutations'; // Import your mutation
import LoadingSpinner from '../../../components/layout/LoadingSpinner';
import './AssignModal.css';
import { capitalize } from '@mui/material';

const AssignModal = ({ onClose, selectedClients, refetchCallList }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [assigning, setAssigning] = useState(false); // Track loading state

    // Fetch users in Team 1
    const { data, loading } = useQuery(listUsersByTeamOne, {
        variables: { limit: 50 },
    });

    // Define mutation
    const [updateCallList] = useMutation(UPDATE_CALL_LIST);

    const users = data?.listUsers?.items || [];

    const handleAssign = async () => {
        if (!selectedUser || selectedClients.length === 0) return;

        const navigatorName = `${capitalize(selectedUser.first_name)} ${capitalize(selectedUser.last_name)}`; // Concatenate name const   

        try {
            setAssigning(true); // Start loading state

            // Execute mutation for each selected client
            await Promise.all(
                selectedClients.map((callId) =>
                    updateCallList({
                        variables: {
                            input: {
                                id: callId, // Call list item ID
                                navigator: navigatorName, // Assign navigator name
                            },
                        },
                    })
                )
            );

            // Optionally refetch the call list to reflect changes
            if (refetchCallList) {
                await refetchCallList();
            }

            // Close the modal on success
            onClose();
        } catch (error) {
            console.error('Error assigning calls:', error);
            alert('Failed to assign calls. Please try again.');
        }
        finally {
            setAssigning(false); // End loading state
        }
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="call-list-assign modal-overlay">
            <div className="modal-content">
                <h2>Assign Client</h2>
                <div className="user-list">
                    {users
                        .slice() // Create a shallow copy of the array to avoid mutating the original
                        .sort((a, b) =>
                            a.first_name.localeCompare(b.first_name) ||
                            a.last_name.localeCompare(b.last_name)
                        )
                        .map((user) => (
                            <div
                                key={user.id}
                                className={`user-item ${selectedUser?.id === user.id ? 'selected' : ''
                                    }`}
                                onClick={() => setSelectedUser(user)} // Store the full user object
                            >
                                <p>
                                    <strong>
                                        {user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)}{' '}
                                        {user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1)}
                                    </strong>
                                </p>
                                <p>{user.thc_email_id}</p>
                                <button
                                    className={`round-button ${selectedUser?.id === user.id ? 'selected' : ''
                                        }`}
                                />
                            </div>
                        ))}
                </div>

                <div className="modal-actions">
                    <button
                        className="assign-btn"
                        onClick={handleAssign}
                        disabled={!selectedUser || assigning} // Disable while loading
                    >
                        {assigning ? 'Assigning...' : 'Assign'}
                    </button>

                    <button className="close-btn" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AssignModal;

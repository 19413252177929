import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { listAllCallLists, listCallListsByNavigator, listClientFix2s, listUsersByTeamOne } from '../../graphql/queries';
import { FixedSizeList as List } from 'react-window';
import { DELETE_CALL_LIST, CREATE_CALL_LIST } from '../../graphql/mutations';
import LoadingSpinner from '../../components/layout/LoadingSpinner';
import AssignModal from './components/AssignModal';
import TouchPointModal from './components/TouchPointModal';
import moment from 'moment';
import './CallList.css';
import { capitalize } from '@mui/material';

const CallList = () => {
  const [selectedTab, setSelectedTab] = useState('new');
  const [selectedCall, setSelectedCall] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [singleSelectedClient, setSingleSelectedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [callStatusFilter, setCallStatusFilter] = useState('all');
  const [contactRoundFilter, setContactRoundFilter] = useState('all');
  const [selectedUser, setSelectedUser] = useState(''); // Selected user
  const [userOptions, setUserOptions] = useState([]); // Dropdown options
  const [filteredCallList, setFilteredCallList] = useState([]); // Filtered list
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isTouchpointModalOpen, setIsTouchpointModalOpen] = useState(false);
  const [isCreatingCallList, setIsCreatingCallList] = useState(false); // Manage loading state

  const [callLists, setCallLists] = useState([]); // Store all fetched call lists
  const [nextToken, setNextToken] = useState(null); // Track pagination token
  const [isLoadingMore, setIsLoadingMore] = useState(false); // Loading state for pagination

  const openAssignModal = () => {
    if (selectedClients.length === 0) {
      alert('Please select at least one client to assign.');
      return;
    }
    setIsAssignModalOpen(true);
  };

  const closeAssignModal = () => {
    setSelectedClients([]); // Clear the selected clients
    setIsAssignModalOpen(false);
  };

  const openTouchpointModal = (call) => {
    setSelectedCall(call); // Save the whole call object
    setIsTouchpointModalOpen(true);
  };

  const closeTouchpointModal = () => {
    setIsTouchpointModalOpen(false);
  };

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0]; // Remove domain part
      setUserDetails({ firstName: capitalize(firstName), lastName: capitalize(lastName) });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  // Query to fetch users
  const { data: usersData, loading: usersLoading } = useQuery(listUsersByTeamOne, {
    variables: { limit: 1000 },
  });

  // Update user options when data is fetched
  useEffect(() => {
    if (usersData?.listUsers?.items) {
      const users = usersData.listUsers.items.map(
        (user) => `${capitalize(user.first_name)} ${capitalize(user.last_name)}`
      );
      setUserOptions(users);
    }
  }, [usersData]);

  useEffect(() => {
    fetchUserDetails();

  }, []);

  const isAdmin = userGroups?.some(group => group === 'Admin' || group === 'Manager');

  const { data, loading, refetch: refetchCallList } = useQuery(
    isAdmin ? listAllCallLists : listCallListsByNavigator,
    {
      variables: isAdmin
        ? { limit: 500 }
        : { navigator: userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : '', limit: 500 },
      skip: !userDetails,
      onCompleted: (fetchedData) => {
        const items = isAdmin
          ? fetchedData?.listCallLists?.items || []
          : fetchedData?.callsByNavigator?.items || [];
        setCallLists(items); // Update the callLists state
        setNextToken(
          isAdmin
            ? fetchedData?.listAllCallLists?.nextToken || null
            : fetchedData?.callsByNavigator?.nextToken || null
        );
      },
    }
  );

  const sanitizedCallLists =
    (isAdmin
      ? data?.listCallLists?.items
      : data?.callsByNavigator?.items
    )?.map((item) => ({
      ...item,
      updatedAt: item.updatedAt || 'N/A',
    })) || [];

  const getFilteredCallListByTab = (tab) => {
    return sanitizedCallLists.filter((call) => {
      if (tab === 'new') return !call.contactRound || call.contactRound === 0;
      if (tab === 'in_progress') return call.contactRound > 0 && call.contactRound <= 2;
      if (tab === 'completed') return call.contactRound > 2;
      return false;
    });
  };
  const [deleteCallList] = useMutation(DELETE_CALL_LIST);
  const [createCallList] = useMutation(CREATE_CALL_LIST); // Import and use your mutation for creating call list items
  const [fetchClients, { data: clientsData, loading: clientsLoading }] = useLazyQuery(listClientFix2s, {
    variables: { limit: 1000 }, // Adjust limit as needed
    fetchPolicy: "network-only",
  });

  const handleCreateCallList = async () => {
    setIsCreatingCallList(true); // Start loading spinner
    try {
      let allClients = [];
      let nextToken = null;

      // Fetch all pages of clients
      do {
        const { data } = await fetchClients({
          variables: {
            limit: 1000, // Maximum number of items per request
            nextToken,   // Fetch the next page using the token
          },
        });

        const fetchedClients = data?.listClientFix2s?.items || [];
        allClients = [...allClients, ...fetchedClients];
        nextToken = data?.listClientFix2s?.nextToken; // Update the nextToken for pagination
      } while (nextToken);

      // Log the total number of clients fetched
      console.log(`Total clients fetched: ${allClients.length}`);

      // Filter clients: exclude those with callStatus "Opt Out" or already in the call list
      const filteredClients = allClients.filter((client) => {
        const isOptOut = client.callStatus === 'Opt Out';
        const alreadyInCallList = sanitizedCallLists.some((call) => call.clientID === client.id);
        return !isOptOut && !alreadyInCallList;
      });

      if (filteredClients.length === 0) {
        alert('No new clients to add to the call list.');
        setIsCreatingCallList(false); // Stop loading spinner
        return;
      }

      // Create call list items for the filtered clients
      for (const client of filteredClients) {
        await createCallList({
          variables: {
            input: {
              clientID: client.id, // Link the client ID
              navigator: 'Unnassigned', // Assign the navigator
              callDate: new Date().toISOString().split('T')[0], // Use current date in ISO format (AWSDate)
              answered: false, // Default answered status
              appointmentMade: false, // Default appointment status
              needsFollowUp: false, // Default follow-up status
              notes: '', // Empty notes for the initial call
              contactRound: client.callStatus === "Completed" ? 3 : 0, // Completed clients default to 3
              followUpScheduledDate: null, // No follow-up date initially
            },
          },
        });
      }

      // Refetch the call list to update UI
      await refetchCallList();
      alert('Call list successfully created!');
    } catch (error) {
      console.error('Error creating call list:', error);
      alert('Failed to create the call list.');
    } finally {
      setIsCreatingCallList(false); // Stop loading spinner
    }
  };

  const handleDeleteCall = async (callId) => {
    if (!callId) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this call?');
    if (!confirmDelete) return;

    try {
      await deleteCallList({
        variables: { input: { id: callId } }, // Ensure this matches your GraphQL schema
      });

      // Refetch the call list after deletion
      refetchCallList();
      alert('Call successfully deleted.');
    } catch (error) {
      console.error('Error deleting call:', error);
      alert('Failed to delete the call.');
    }
  };

  const newCount = getFilteredCallListByTab('new').length;
  const inProgressCount = getFilteredCallListByTab('in_progress').length;
  const completedCount = getFilteredCallListByTab('completed').length;

  const getFilteredCallList = () => {
    let filteredList = getFilteredCallListByTab(selectedTab).filter((call) => {
      if (callStatusFilter === 'called' && !call.answered) return false;
      if (callStatusFilter === 'not_called' && call.answered) return false;
      if (callStatusFilter === 'appointment_made' && !call.appointmentMade) return false;
      if (callStatusFilter === 'no_appointment' && call.appointmentMade) return false;

      if (contactRoundFilter !== 'all' && call.contactRound !== parseInt(contactRoundFilter, 10)) return false;

      if (selectedUser && call.navigator !== selectedUser) return false; // Add this line

      const nameMatch = call.client?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const lastNameMatch = call.client?.last_name?.toLowerCase().includes(searchTerm.toLowerCase());
      const dateMatch = call.callDate?.includes(searchTerm);

      return nameMatch || lastNameMatch || dateMatch;
    });

    // Apply sorting
    if (sortConfig.key) {
      filteredList = filteredList.sort((a, b) => {
        const aValue = sortConfig.key.split('.').reduce((o, i) => o?.[i], a);
        const bValue = sortConfig.key.split('.').reduce((o, i) => o?.[i], b);

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return filteredList;
  };
  useEffect(() => {
    const fetchAllCallLists = async () => {
      setCallLists([]); // Reset the call list before fetching
      let allItems = [];
      let token = null;
  
      try {
        do {
          const { data } = await refetchCallList({
            variables: {
              limit: 500,
              nextToken: token,
            },
          });
  
          const items = isAdmin
            ? data?.listAllCallLists?.items || []
            : data?.callsByNavigator?.items || [];
  
          allItems = [...allItems, ...items]; // Append new items to the total list
          token = isAdmin
            ? data?.listAllCallLists?.nextToken || null
            : data?.callsByNavigator?.nextToken || null;
        } while (token);
  
        setCallLists(allItems); // Update the state with all fetched items
        console.log(`Total items fetched: ${allItems.length}`);
      } catch (error) {
        console.error('Error fetching call lists:', error);
      }
    };
  
    fetchAllCallLists();
  }, [isAdmin, userDetails, refetchCallList]);
  

  const filteredCallLists = getFilteredCallList();

  const listRef = useRef();

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(0); // Scroll to the top after filter changes
    }
  }, [filteredCallLists]);

  useEffect(() => {
    if (data) {
      const items = isAdmin
        ? data?.listCallLists?.items || []
        : data?.callsByNavigator?.items || [];
      setCallLists(items);
      setNextToken(
        isAdmin
          ? data?.listAllCallLists?.nextToken || null
          : data?.callsByNavigator?.nextToken || null
      );
    }
  }, [data]);


  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  if (loading || !userDetails) {
    return <LoadingSpinner />;
  }
  const loadMoreCallLists = async () => {
    if (!nextToken || isLoadingMore) return; // Avoid duplicate calls
    setIsLoadingMore(true);

    try {
      const { data } = await refetchCallList({
        variables: {
          limit: 500,
          nextToken,
        },
      });

      const newItems = data?.listAllCallLists?.items || [];
      setCallLists((prev) => [...prev, ...newItems]); // Append new items
      setNextToken(data?.listAllCallLists?.nextToken || null);
    } catch (error) {
      console.error('Error loading more call lists:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };
  const Row = ({ index, style, data }) => {
    const call = data[index]; // Use data passed from List
    if (!call) return null;

    return (
      <div
        style={style}
        className={`call-list-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
      >
        <div>
          {isAdmin ? (
            <input
              type="checkbox"
              checked={selectedClients.includes(call.id)}
              onChange={(e) => onSelect(e, call.id, 'multi')}
            />
          ) : (
            <input
              type="radio"
              name="selectedClient"
              className="round-radio"
              checked={singleSelectedClient?.id === call.id}
              onChange={() => onSelect(null, call.id, 'single')}
            />
          )}
        </div>
        <div>{call.client?.name || 'N/A'}</div>
        <div>{call.client?.last_name || 'N/A'}</div>
        <div>{call.navigator || 'Unassigned'}</div>
        <div>{moment(call.callDate).format('MM-DD-YYYY')}</div>
        <div>{call.contactRound || 0}</div>
        <div>{call.client?.prefered_lang || 'N/A'}</div>
        <div>{call.client?.county || 'N/A'}</div>
        <div>{call.client?.zip_code || 'N/A'}</div>
      </div>
    );
  };

  const onSelect = (event, id, type) => {
    if (type === 'multi') {
      // Multi-select logic for Admin
      if (event.target.checked) {
        setSelectedClients((prev) => [...prev, id]); // Add to selected list
      } else {
        setSelectedClients((prev) => prev.filter((clientId) => clientId !== id)); // Remove from selected list
      }
    } else if (type === 'single') {
      // Single-select logic for non-Admin
      const selectedCall = callLists.find((call) => call.id === id); // Find the selected call
      setSingleSelectedClient(selectedCall);
      setSelectedCall(selectedCall);
    }
  };

  // Throttled Load
  const throttledLoadMore = () => {
    if (!isLoadingMore) {
      loadMoreCallLists();
    }
  };

  

  return (
    <div className="call-list-container">
      <header className="clients-header">
        <h1>Call List</h1>
      </header>

      {/* Filter Control */}
      <div className="call-list-filter-control">
        <h3>Filter Call List:</h3>

        {/* Search input */}
        <input
          type="text"
          placeholder="Search by name, last name, or date"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Call status filter */}
        <select value={callStatusFilter} onChange={(e) => setCallStatusFilter(e.target.value)}>
          <option value="all">All Clients</option>
          <option value="called">Called Clients</option>
          <option value="not_called">Not Called Clients</option>
          <option value="appointment_made">Appointment Made</option>
          <option value="no_appointment">No Appointment Made</option>
        </select>

        {/* Contact round filter */}
        <select value={contactRoundFilter} onChange={(e) => setContactRoundFilter(e.target.value)}>
          <option value="all">All Rounds</option>
          <option value="1">Round 1</option>
          <option value="2">Round 2</option>
          <option value="3">Round 3</option>
        </select>
        <select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          className="user-select-dropdown"
        >
          <option value="">Select User</option> {/* Default empty option */}
          {userOptions.map((user, index) => (
            <option key={index} value={user}>
              {user}
            </option>
          ))}
        </select>

        <button
          className="create-call-list-button"
          onClick={handleCreateCallList}
          disabled={isCreatingCallList} // Disable the button while loading
        >
          {isCreatingCallList ? <LoadingSpinner /> : 'Create Call List'}
        </button>
      </div>

      {/* Tab Navigation */}
      <div className="call-list-tabs">
        <button
          className={`call-list-tab ${selectedTab === 'new' ? 'active-tab' : ''}`}
          onClick={() => handleTabChange('new')}
        >
          New ({newCount})
        </button>
        <button
          className={`call-list-tab ${selectedTab === 'in_progress' ? 'active-tab' : ''}`}
          onClick={() => handleTabChange('in_progress')}
        >
          In Progress ({inProgressCount})
        </button>
        <button
          className={`call-list-tab ${selectedTab === 'completed' ? 'active-tab' : ''}`}
          onClick={() => handleTabChange('completed')}
        >
          Completed by Assister ({completedCount})
        </button>
      </div>

      {/* Call List Table */}
      {/* <table>
        <thead>
          <tr>
            <th>Selection</th>
            <th onClick={() => handleSort('client.name')}>
              Client Name {sortConfig.key === 'client.name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('client.last_name')}>
              Client Last Name {sortConfig.key === 'client.last_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('navigator')}>
              Navigator {sortConfig.key === 'navigator' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('callDate')}>
              Call Date {sortConfig.key === 'callDate' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('contactRound')}>
              Contact Round {sortConfig.key === 'contactRound' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('client.prefered_lang')}>
              Preferred Language {sortConfig.key === 'client.prefered_lang' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('client.county')}>
              County {sortConfig.key === 'client.county' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('client.zip_code')}>
              Zip Code {sortConfig.key === 'client.zip_code' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
            </th>
          </tr>
        </thead>

        <tbody>
          {getFilteredCallList().map((call) => (
            <tr key={call.id}>
              <td>
                {isAdmin ? (
                  <input
                    type="checkbox"
                    checked={selectedClients.includes(call.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedClients((prev) => [...prev, call.id]);
                      } else {
                        setSelectedClients((prev) => prev.filter((id) => id !== call.id));
                      }
                    }}
                  />
                ) : (
                  <input
                    type="radio"
                    name="selectedClient"
                    className="round-radio"
                    checked={singleSelectedClient?.id === call.id}
                    onChange={() => {
                      setSingleSelectedClient(call);
                      setSelectedCall(call);
                    }}
                  />
                )}
              </td>
              <td
                className={isAdmin ? 'clickable-client-name' : ''}
                onClick={() => {
                  if (isAdmin) {
                    openTouchpointModal(call); // Allow admins to open the touchpoint modal
                  }
                }}
              >
                {capitalize(call.client?.name) || 'N/A'}
              </td>
              <td>{capitalize(call.client?.last_name) || 'N/A'}</td>
              <td>{call.navigator}</td>
              <td>{moment(call.callDate).format('MM-DD-YYYY')}</td>
              <td>{call.contactRound || '0'}</td>
              <td>{call.client?.prefered_lang || 'N/A'}</td>
              <td>{call.client?.county || 'N/A'}</td>
              <td>{call.client?.zip_code || 'N/A'}</td>
              {isAdmin && (
                <td>
                  <button
                    className="delete-call-button"
                    onClick={() => handleDeleteCall(call.id)}
                  >
                    Delete
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table> */}
      {/* Table Header */}
      <div className="table-header">
        <div>Selection</div>
        <div>Client Name</div>
        <div>Client Last Name</div>
        <div>Navigator</div>
        <div>Call Date</div>
        <div>Contact Round</div>
        <div>Preferred Language</div>
        <div>County</div>
        <div>Zip Code</div>
      </div>

      <List
        ref={listRef}
        height={500}
        itemCount={filteredCallLists.length}
        itemSize={50}
        width="100%"
        itemData={filteredCallLists}
        onItemsRendered={({ visibleStopIndex }) => {
          if (visibleStopIndex >= filteredCallLists.length - 1 && nextToken) {
            throttledLoadMore();
          }
        }}
      >
        {Row}
      </List>
      <div className="total-items-info">
        <h3>Total Call List Items Loaded: {callLists.length}</h3>
      </div>

      <div className="action-button-container">
        {isAdmin ? (
          <button
            className="action-button"
            onClick={() => {
              if (selectedClients.length > 0) {
                openAssignModal(); // Open Assign Modal for Admins
              } else {
                alert('Please select at least one client to assign.');
              }
            }}
          >
            Assign
          </button>
        ) : (
          <button
            className="action-button"
            onClick={() => {
              if (selectedCall) {
                openTouchpointModal(selectedCall); // Pass the whole call object for non-admins
              } else {
                alert('Please select a client using the round button to create a touchpoint.');
              }
            }}
          >
            Create Touchpoint
          </button>
        )}
      </div>

      {isAssignModalOpen && (
        <AssignModal
          onClose={() => {
            closeAssignModal();
            refetchCallList(); // Refetch data after closing the modal
          }}
          selectedClients={selectedClients}
          refetchCallList={refetchCallList}
        />
      )}
      {isTouchpointModalOpen && (
        <TouchPointModal
          onClose={closeTouchpointModal}
          selectedCall={selectedCall} // Pass the full call object
        />
      )}
    </div>
  );
};

export default CallList;

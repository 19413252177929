import React, { useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { GET_ALL_LOCATIONS, GET_CITY_BY_NAME } from '../../graphql/queries';
import { CREATE_LOCATION, CREATE_CITY } from '../../graphql/mutations';
import './Locations.css';

const Locations = ({ isOpen, onClose }) => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_LOCATIONS, {
        fetchPolicy: 'network-only',
    });
    const [fetchCityByName] = useLazyQuery(GET_CITY_BY_NAME);
    const [createLocation] = useMutation(CREATE_LOCATION);
    const [createCity] = useMutation(CREATE_CITY);

    const [locationName, setLocationName] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
    const [cityName, setCityName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleAddLocation = async () => {
        if (!locationName || !locationAddress || !cityName) {
            alert('Please fill in all fields.');
            return;
        }

        setIsSubmitting(true);

        try {
            // Check if the city exists
            const { data: cityData } = await fetchCityByName({
                variables: { name: cityName },
            });

            let cityID;
            if (cityData?.listCities?.items?.length > 0) {
                // City exists
                cityID = cityData.listCities.items[0].id;
            } else {
                // Create a new city
                const { data: newCityData } = await createCity({
                    variables: { input: { name: cityName } },
                });
                cityID = newCityData.createCity.id;
            }

            // Create the location
            await createLocation({
                variables: {
                    input: {
                        name: locationName,
                        address: locationAddress,
                        cityID,
                        outreachDate: '2024-08-10',
                    },
                },
            });

            alert('Location added successfully!');
            refetch(); // Refresh the location list
            setLocationName('');
            setLocationAddress('');
            setCityName('');
        } catch (err) {
            console.error('Error adding location:', err);
            alert('An error occurred while adding the location.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="locations-modal-overlay"
            onClick={(e) => {
                if (e.target.classList.contains('locations-modal-overlay')) {
                    onClose(); // Close the modal if the overlay is clicked
                }
            }}
        >
            <div
                className="locations-modal"
                onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
            >
                <h2>Add New Location</h2>
                <div className="modal-form">
                    <label>
                        Location Name:
                        <input
                            type="text"
                            value={locationName}
                            onChange={(e) => setLocationName(e.target.value)}
                            placeholder="Enter location name"
                        />
                    </label>
                    <label>
                        Address:
                        <input
                            type="text"
                            value={locationAddress}
                            onChange={(e) => setLocationAddress(e.target.value)}
                            placeholder="Enter address"
                        />
                    </label>
                    <label>
                        City:
                        <input
                            type="text"
                            value={cityName}
                            onChange={(e) => setCityName(e.target.value)}
                            placeholder="Enter city name"
                        />
                    </label>
                    <button
                        onClick={handleAddLocation}
                        disabled={isSubmitting}
                        className="add-button"
                    >
                        {isSubmitting ? 'Adding...' : 'Add Location'}
                    </button>
                    <button onClick={onClose} className="cancel-button">
                        Cancel
                    </button>
                </div>
                <h3>Existing Locations</h3>
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error loading locations: {error.message}</p>
                ) : (
                    <ul className="locations-list">
                        {data.listLocations.items.map((loc) => (
                            <li key={loc.id}>
                                <strong>{loc.name}</strong> - {loc.address}, {loc.city?.name || 'N/A'}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Locations;

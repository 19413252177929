import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import LoadingSpinner from '../../../components/layout/LoadingSpinner';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { GET_INTERACTIONS_BY_CALL_LIST } from '../../../graphql/queries';
import { CREATE_INTERACTION, UPDATE_CALL_LIST, DELETE_INTERACTION, CREATE_OPT_OUT_CLIENT, DELETE_CLIENTFIX2, DELETE_CALL_LIST } from '../../../graphql/mutations';
import './TouchPointModal.css';
import { capitalize } from '../../../components/common/capitalize';

const TouchPointModal = ({ onClose, selectedCall }) => {
  const [isAddInteractionModalOpen, setIsAddInteractionModalOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [interactionDetails, setInteractionDetails] = useState({
    callDate: '',
    time: '',
    answered: false,
    appointmentMade: false,
    notes: '',
    contactMethod: '',
    followUpScheduledDate: '',
  });
  const [addToOptOut, setAddToOptOut] = useState(false);
  const callListID = selectedCall?.id;

  const { data, loading, error, refetch } = useQuery(GET_INTERACTIONS_BY_CALL_LIST, {
    variables: { callListID },
    skip: !callListID,
  });

  const [updateCallList] = useMutation(UPDATE_CALL_LIST);
  const [deleteInteraction] = useMutation(DELETE_INTERACTION);
  const [createInteraction, { loading: createLoading, error: createError }] = useMutation(CREATE_INTERACTION, {
    onCompleted: () => {
      refetch(); // Refresh interaction list
      setIsAddInteractionModalOpen(false); // Close the modal
    },
  });
  const [createOptOutClient] = useMutation(CREATE_OPT_OUT_CLIENT);
  const [deleteClientFix2] = useMutation(DELETE_CLIENTFIX2);
  const [deleteCallList] = useMutation(DELETE_CALL_LIST);

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0]; // Remove domain part
      setUserDetails({ firstName: capitalize(firstName), lastName: capitalize(lastName) });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleInteractionSubmit = async () => {
    if (!callListID) {
      console.error('callListID is missing.');
      return;
    }

    try {
      // Step 1: If opt-out is checked, skip interaction creation
      if (addToOptOut) {
        const client = selectedCall.client;

        await createOptOutClient({
          variables: {
            input: {
              id: client.id,
              name: client.name,
              last_name: client.last_name,
              address: client.address,
              city: client.city,
              county: client.county,
              zip_code: client.zip_code,
              prefered_lang: client.prefered_lang,
              email: client.email,
              insurance_picked: client.insurance_picked,
              plan: client.plan,
              payment_per_month: client.payment_per_month,
              phone: client.phone,
              navigator: client.navigator,
              optOutDate: new Date().toISOString(),
              callStatus: 'Opted Out',
            },
          },
        });

        // Step 2: Remove client from clients table
        await deleteClientFix2({
          variables: {
            input: {
              id: client.id,
            },
          },
        });

        // Step 3: Remove client from call list
        await deleteCallList({
          variables: {
            input: {
              id: callListID,
            },
          },
        });

        refetch(); // Refresh interaction list
        setIsAddInteractionModalOpen(false); // Close the modal
        return; // Skip the rest of the function
      }

      // Step 4: Add interaction
      const interactionInput = { ...interactionDetails }; // Clone interaction details
      delete interactionInput.addToOptOut; // Ensure addToOptOut is not part of input

      const interactionResponse = await createInteraction({
        variables: {
          input: {
            ...interactionInput,
            callListID,
            createdBy: 'currentUser', // Replace with actual user info
          },
        },
      });

      // Step 5: Update call list
      await updateCallList({
        variables: {
          input: {
            id: callListID,
            contactRound: (selectedCall.contactRound || 0) + 1, // Increment contact round
            followUpScheduledDate: interactionDetails.followUpScheduledDate || null, // Update follow-up date if provided
          },
        },
      });

      refetch(); // Refresh interaction list
      setIsAddInteractionModalOpen(false); // Close the modal
    } catch (err) {
      console.error('Error processing interaction:', err);
    }
  };

  const handleDeleteInteraction = async (interactionID) => {
    if (!callListID) {
      console.error('callListID is missing.');
      return;
    }

    try {
      // Delete the interaction
      await deleteInteraction({ variables: { id: interactionID } });

      // Update the CallList's contact round
      await updateCallList({
        variables: {
          input: {
            id: callListID,
            contactRound: Math.max((selectedCall.contactRound || 1) - 1, 0), // Decrement contact round but ensure it doesn't go below 0
          },
        },
      });

      refetch(); // Refresh interaction list
    } catch (err) {
      console.error('Error deleting interaction or updating call list:', err);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="error-message">Error loading interactions: {error.message}</div>;
  }

  const interactions = data?.interactionsByCallList?.items || [];

  // console.log(selectedCall);

  return (
    <div
      className="touchpoint-modal-overlay"
      onClick={onClose}
    >
      <div
        className="touchpoint-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
        <h2>Information for {capitalize(selectedCall.client?.name) + ' ' + capitalize(selectedCall.client?.last_name)}</h2>
        <div className="client-details">
          <p>
            <strong>Name:</strong> {capitalize(selectedCall.client?.name) || 'N/A'}{' '}
            {capitalize(selectedCall.client?.last_name) || 'N/A'}
          </p>
          <p><strong>Navigator:</strong> {selectedCall.client.navigator || 'N/A'}</p>
          <p><strong>Call Date:</strong> {selectedCall.callDate || 'N/A'}</p>
          <p><strong>Follow-Up Date:</strong> {selectedCall.followUpScheduledDate || 'N/A'}</p>
          <p><strong>Contact Round:</strong> {selectedCall.contactRound || 'N/A'}</p>
        </div>
        <div className="interactions-list">
          <h3>Previous Interactions</h3>
          {interactions.length > 0 ? (
            interactions.map((interaction) => (
              <div key={interaction.id} className="interaction-item">
                <p><strong>Call Date:</strong> {interaction.callDate || 'N/A'}</p>
                <p><strong>Time:</strong> {interaction.time || 'N/A'}</p>
                <p><strong>Answered:</strong> {interaction.answered ? 'Yes' : 'No'}</p>
                <p><strong>Appointment Made:</strong> {interaction.appointmentMade ? 'Yes' : 'No'}</p>
                <p><strong>Contact Method:</strong> {interaction.contactMethod || 'N/A'}</p>
                <p><strong>Created By:</strong> {interaction.createdBy || 'N/A'}</p>
                {/* Delete Button */}
                <button
                  className="delete-interaction-button"
                  onClick={() => handleDeleteInteraction(interaction.id)}
                >
                  Delete
                </button>
              </div>
            ))
          ) : (
            <p>No interactions found.</p>
          )}
        </div>


        {/* Add Interaction Button */}
        <div className="action-buttons">
          <button
            className="add-interaction-button"
            onClick={() => setIsAddInteractionModalOpen(true)}
          >
            Add Interaction
          </button>
        </div>

        {/* Add Interaction Modal */}
        {isAddInteractionModalOpen && (
          <div className="add-interaction-modal-overlay" onClick={() => setIsAddInteractionModalOpen(false)}>
            <div
              className="add-interaction-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <h3>Add Interaction</h3>
              <label>
                Add to Opt-Out List:
                <input
                  type="checkbox"
                  checked={addToOptOut}
                  onChange={(e) => setAddToOptOut(e.target.checked)}
                />
              </label>
              <label>
                Call Date:
                <input
                  type="date"
                  value={interactionDetails.callDate}
                  onChange={(e) => setInteractionDetails({ ...interactionDetails, callDate: e.target.value })}
                  disabled={addToOptOut} // Disable if opting out
                />
              </label>
              <label>
                Time:
                <input
                  type="time"
                  value={interactionDetails.time}
                  onChange={(e) => setInteractionDetails({ ...interactionDetails, time: e.target.value })}
                  disabled={addToOptOut} // Disable if opting out
                />
              </label>
              <label>
                Answered:
                <input
                  type="checkbox"
                  checked={interactionDetails.answered}
                  onChange={(e) => setInteractionDetails({ ...interactionDetails, answered: e.target.checked })}
                  disabled={addToOptOut} // Disable if opting out
                />
              </label>
              <label>
                Appointment Made:
                <input
                  type="checkbox"
                  checked={interactionDetails.appointmentMade}
                  onChange={(e) =>
                    setInteractionDetails({ ...interactionDetails, appointmentMade: e.target.checked })
                  }
                  disabled={addToOptOut} // Disable if opting out
                />
              </label>
              <label>
                Notes:
                <textarea
                  value={interactionDetails.notes}
                  onChange={(e) => setInteractionDetails({ ...interactionDetails, notes: e.target.value })}
                  disabled={addToOptOut} // Disable if opting out
                />
              </label>
              <label>
                Contact Method:
                <input
                  type="text"
                  value={interactionDetails.contactMethod}
                  onChange={(e) =>
                    setInteractionDetails({ ...interactionDetails, contactMethod: e.target.value })
                  }
                  disabled={addToOptOut} // Disable if opting out
                />
              </label>
              <label>
                Follow-Up Scheduled Date:
                <input
                  type="date"
                  value={interactionDetails.followUpScheduledDate}
                  onChange={(e) =>
                    setInteractionDetails({
                      ...interactionDetails,
                      followUpScheduledDate: e.target.value,
                    })
                  }
                  disabled={addToOptOut} // Disable if opting out
                />
              </label>
              <button onClick={handleInteractionSubmit} disabled={createLoading}>
                {createLoading ? 'Adding...' : 'Add Interaction'}
              </button>
              {createError && <p className="error-message">Error: {createError.message}</p>}
              <button onClick={() => setIsAddInteractionModalOpen(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TouchPointModal;

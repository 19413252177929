// EquipmentTable.js
import React, { useState } from 'react';
import './EquipmenteTable.css';
import useFilteredData from './useFilteredDate.jsx';


const EquipmentTable = ({ title, data, totalPurchase, itemCount, selectedDateType,onRowSelection,  selectedRows = [], // Valor por defecto para evitar undefined
  totalSelectedPrice}) => {

  // Mapeo de los campos de fecha a sus etiquetas legibles
  const dateTypeLabels = {
    purchase_date: 'Purchase Date',
    warranty_date: 'Warranty Date',
    returnable_date: 'Returnable Date',
    assignment_date: 'Assignment Date',
    // Añade más tipos si es necesario
  };

  // Si selectedDateType no tiene un valor, muestra un texto por defecto
  const dateLabel = dateTypeLabels[selectedDateType] || ' No Date';


  // Formato de Fecha
  const formatDate = (dateString) => {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return localDate.toLocaleDateString(undefined, options);
  };


  if (!data || data.length === 0) return null; // No mostrar tabla si no hay datos

  return (

    <div className="equipment-table">

      <h2 style={{ marginBottom: "5px" }}>{title}</h2>
      {selectedDateType === "purchase_date" && (
        <div style={{ textAlign: "center", fontWeight: "bold", marginBottom: "10px" }}>
          {`${title} Purchase Total: $${totalPurchase}`}
        </div>

      )}

      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    const allIds = data.map((item) => item.id);
                    allIds.forEach((id) => onRowSelection(id));
                  } else {
                    data.map((item) => onRowSelection(item.id));
                  }
                }}
                checked={data.length > 0 && data.every((item) => selectedRows.includes(item.id))}
              />
            </th>
            <th>Item</th>
            <th >Tag ID</th>
            <th >Brand</th>
            <th >Price</th>
            <th >Store</th>
            <th >Serial Number</th>
            <th >Assigned to</th>
            <th>Location</th>
            <th>{dateLabel}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(item.id)}
                  onChange={() => onRowSelection(item.id)}
                />
              </td>
              <td>{title}</td>
              <td>{item.tagId || item.tagid ||item.name }</td>
              <td>{item.brand}</td>
              <td>{item.price}</td>
              <td>{item.store}</td>
              <td>{item.serial_Number}</td>
              <td>{item.assigned_to}</td>
              <td>{item.location}</td>
              <td>{formatDate(item[selectedDateType])}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="10" style={{ fontWeight: "bold", textAlign: "center" }}>
              {`Total ${title}s: ${itemCount}`}
            </td>
            <td colSpan="10" style={{ fontWeight: 'bold', textAlign: 'center', display:'none' }}>
              {`Total Selected Price: $${totalSelectedPrice}`}
            </td>
          </tr>
          
        </tfoot>
      </table>
    </div>


  );
};

export default EquipmentTable;

import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listDeleteEquipmentHistories, listScanners, listUsers } from '../../../graphql/queries';
import { createDeleteEquipmentHistory, createScanner, deleteScanner, updateScanner } from '../../../graphql/mutations';
import './Scanner.css';
import { v4 as uuidv4 } from 'uuid';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';
import useFilteredData from '../../../components/layout/useFilteredDate';
import SearchBar from '../../../components/layout/SearchBar';
import { capitalize } from '../../../components/common/capitalize';

const Scanner = () => {
    const [scanner, setScanner] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showScannerAddOverlay, setShowScannerAddOverlay] = useState(false);
    const [selectedScanner, setSelectedScanner] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const { loading1, error: userError, data: userData } = useQuery(listUsers);
    const [searchTerm, setSearchTerm] = useState('');

    const [newScanner, setNewScanner] = useState({
        id: '',
        tagid: '',
        model: '',
        model_number: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        charger_cable: false,
        scanner_type: '',
        Connector_cable: '',
        wireless: false,
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: '',
        status: '',
        assigned_to: '',
        location: '',
        team: '',
    });
    const [sortConfig, setSortConfig] = useState(null);
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');



    const handleSaveAsExcel = async () => {
        try {
            const scannerToExport = filteredScanners.length > 0 ? filteredScanners : scanner;

            if (scannerToExport.length > 0) {
                // Sort the Scanners by Timestamp in ascending order (earliest first)
                const sortedScanner = [...scannerToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedScanner.map((scanner, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG': scanner.tagid,
                    'Brand': scanner.brand,
                    'Available': scanner.available ? 'Yes' : 'No',
                    'Serial Number': scanner.serial_Number,
                    'Status': scanner.status,
                    'Assigned to': scanner.assigned_to,


                }));


                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Scanners']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 10 }, // Adjust width for each column
                    { wch: 15 },
                    { wch: 10 },
                    { wch: 18 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 25 },

                ];
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Scanners');

                // Write file
                XLSX.writeFile(workbook, 'ScannersData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditScanner = (scanner) => {
        setSelectedScanner(scanner);
        setIsEditing(true);
        setShowScannerAddOverlay(true);
    };
    const { loading, data, fetchMore } = useQuery(listScanners, {
        variables: { limit: 100 },
    });

    const [addScanner] = useMutation(createScanner, {
        refetchQueries: [{ query: listScanners, variables: { limit: 100 } }]
    });

    const [removeScanner] = useMutation(deleteScanner, {
        refetchQueries: [{ query: listScanners, variables: { limit: 100 } }]
    });
    const [updScanner] = useMutation(updateScanner, {
        refetchQueries: [{ query: listScanners, variables: { limit: 100 } }]
    });

    const [addDeleteEquipmentHistory] = useMutation(createDeleteEquipmentHistory, {
        refetchQueries: [{ query: listDeleteEquipmentHistories, variables: { limit: 100 } }]
    });
    const [fetchAllScanners, { loading: countLoading, data: allScannersData }] = useLazyQuery(listScanners, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllScanners = async () => {
            let allScanners = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                allScanners = [...allScanners, ...data.listScanners.items];
                currentNextToken = data.listScanners.nextToken;
            } while (currentNextToken);

            setScanner(allScanners);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllScanners();
        }
    }, [data, fetchMore]);

    const clearNewScanner = () => {
        setNewScanner({
            id: '',
            tagid: '',
            model: '',
            model_number: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            charger_cable: '',
            scanner_type: '',
            Connector_cable: '',
            wireless: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: '',
            status: '',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listScanners.nextToken);
                return {
                    listScanners: {
                        ...fetchMoreResult.listScanners,
                        items: [...prevResult.listScanners.items, ...fetchMoreResult.listScanners.items]
                    }
                };
            }
        });
    };

    //Filtro de Monitors empleando el componente useFilteredData
    const filteredScanners = useFilteredData(scanner || [], searchTerm, sortConfig);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }
    
        if (isEditing) {
            setSelectedScanner({
                ...selectedScanner,
                [name]: formattedValue
            });
        } else {
            setNewScanner({
                ...newScanner,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddScanner = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newScanner.purchase_date
            ? new Date(newScanner.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newScanner.assignment_date
            ? new Date(newScanner.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newScanner.warranty_date
            ? new Date(newScanner.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newScanner.returnable_date
            ? new Date(newScanner.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;
        if (newScanner.price !== "" && newScanner.price !== null && newScanner.price != undefined) {
            priceValue = parseFloat(newScanner.price.trim());

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        console.log('Entered price:', newScanner.price);
        setPriceError('');


        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = scanner.some(scanner =>
            scanner.tagid === newScanner.tagid &&
            scanner.serial_Number === newScanner.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A scanner with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }
        // Check if newScanner and its properties are defined
        if (!newScanner || typeof newScanner.status === 'undefined' || typeof newScanner.available === 'undefined') {
            console.error('Scanner data is incomplete or invalid.');
            setStatusError('Scanner data is incomplete or invalid.');
            return;
        }

        // Check availability first
        if (newScanner.available === 'true') {

            // If available, status must be Inventario
            if (newScanner.status !== 'inv') {
                setStatusError('If the Scanner is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newScanner.assigned_to !== '' && newScanner.assigned_to !== 'n/a') {
                setStatusError('If the Scanner is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newScanner.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newScanner.status)) {
                setStatusError('If the Scanner is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }


        // If all validations pass, proceed with adding the scanner to the database
        addScanner({

            variables: { input: { ...newScanner, price: priceValue, purchase_date: formattedPurchase, assignment_date: formattedAssignment, warranty_date: formattedWarranty, returnable_date: formattedReturnable } }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error adding scanner:", error);
        });
    };

    const removeUnnecessaryFields = (data, fieldsToRemove) => {
        fieldsToRemove.forEach((field) => {
            delete data[field];
        });
        return data;
    };
    const handleDeleteScanner = async () => {
        try {
            // Remover campos innecesarios
            const ScannerData = removeUnnecessaryFields({ ...selectedScanner }, [
                'charger_cable',
                'scanner_type',
                'Connector_cable',
                'model_number',
                'wireless',
                'warranty_date',
                'returnable_date',
                'status',
                'team',
            ]);

            // Lista de campos permitidos según el esquema GraphQL
            const allowedFields = [
                'id',
                'Item',
                'tagId',
                'tagid', // Agregado
                'serial_Number',
                'price',
                'brand',
                'model',
                'store',
                'purchase_date',
                'assignment_date',
                'available',
                'previous_employees',
                'assigned_to', // Agregado
                'location',
                'notes',
            ];

            // Filtrar los campos permitidos
            const cleanedNewScanners = Object.fromEntries(
                Object.entries(ScannerData).filter(([key]) => allowedFields.includes(key))
            );


            console.log("Antes de normalizar:", cleanedNewScanners);

            // Normalizar claves alternativas
            cleanedNewScanners.tagId = cleanedNewScanners.tagId || cleanedNewScanners.tagid;
            delete cleanedNewScanners.tagid;

            cleanedNewScanners.previous_employees =
                cleanedNewScanners.previous_employees || cleanedNewScanners.assigned_to;
            delete cleanedNewScanners.assigned_to;

            console.log("Despues de normalizar:", cleanedNewScanners);

            // Añadir valores clave predeterminados
            if (!cleanedNewScanners.Item) {
                cleanedNewScanners.Item = "Scanner"; // Establecer el valor predeterminado para `Item`
            }

            // Eliminar valores vacíos
            Object.keys(cleanedNewScanners).forEach((key) => {
                if (cleanedNewScanners[key] === "" || cleanedNewScanners[key] === null) {
                    delete cleanedNewScanners[key];
                }
            });

            // Agregar empleado a la tabla PreviousEmployee
            await addDeleteEquipmentHistory({
                variables: { input: cleanedNewScanners },
            });

            // Eliminar el Scanner
            await removeScanner({
                variables: { input: { id: cleanedNewScanners.id } },
            });

            // Resetear estados
            setShowScannerAddOverlay(false);
            setIsEditing(false);
            setSelectedScanner(null);

        } catch (error) {
            console.error("Error deleting Scanner", error);
        }
    };


    const handleUpdateScanner = () => {

        const formattedData_purchase = selectedScanner.purchase_date ? new Date(selectedScanner.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedScanner.warranty_date ? new Date(selectedScanner.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedScanner.returnable_date ? new Date(selectedScanner.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedScanner.assignment_date ? new Date(selectedScanner.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedScanner.price !== "" && selectedScanner.price !== null && selectedScanner.price != undefined) {
            priceValue = parseFloat(selectedScanner.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first

        console.log(selectedScanner.available);
        console.log(selectedScanner.status);
        console.log(selectedScanner.assigned_to);


        if (selectedScanner.available === 'true') {
            // If available, status must be 'Inventario'
            if (selectedScanner.status !== 'inv') {
                setStatusError('If the Scanner is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }

            // If available and status is 'Inventario', assigned_to must be empty or "N/A"
            if (selectedScanner.assigned_to !== '' && selectedScanner.assigned_to.toLowerCase() !== 'n/a') {
                setStatusError('If the Scanner is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (selectedScanner.available === 'false') {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedScanner.status)) {
                setStatusError('If the Scanner is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }

            // Clear error if validation passes
            setStatusError('');
        }


        updScanner({
            variables: {
                input: {
                    id: selectedScanner.id,
                    tagid: selectedScanner.tagid,
                    model: selectedScanner.model,
                    model_number: selectedScanner.model_number,
                    brand: selectedScanner.brand,
                    serial_Number: selectedScanner.serial_Number,
                    price: priceValue,
                    store: selectedScanner.store,
                    purchase_date: formattedData_purchase,
                    charger_cable: selectedScanner.charger_cable,
                    scanner_type: selectedScanner.scanner_type,
                    Connector_cable: selectedScanner.Connector_cable,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedScanner.available,
                    status: selectedScanner.status,
                    assigned_to: selectedScanner.assigned_to,
                    location: selectedScanner.location,
                    team: selectedScanner.team
                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowScannerAddOverlay(false);
            setPriceError('');
            setStatusError('');
            setDuplicateError('');

        }).catch(error => {
            console.error("Error updating scanner:", error);
        });
    };


    const handleSelectScanner = (scanner) => {
        console.log('Selected scanner:', scanner); // Debugging log to check scanner details
        console.log('Selected scanner Types:');
        for (const [key, value] of Object.entries(scanner)) {
            console.log(`${key}: ${typeof value}`);
        }
        const formatDateForInput = (dateString) => {
            if (!dateString) return ''; // Return empty string if the date is not provided
            const date = new Date(dateString);
            return date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
        };

        setNewScanner({
            id: scanner.id,
            tagid: scanner.tagid,
            model: scanner.model,
            model_number: scanner.model_number,
            brand: scanner.brand,
            serial_Number: scanner.serial_Number,
            price: scanner.price,
            store: scanner.store,
            purchase_date: formatDateForInput(scanner.purchase_date),
            charger_cable: scanner.charger_cable,
            Connector_cable: scanner.Connector_cable,
            wireless: scanner.wireless,
            ink_type: scanner.ink_type,
            assignment_date: formatDateForInput(scanner.assignment_date),
            warranty_date: formatDateForInput(scanner.warranty_date),
            returnable_date: formatDateForInput(scanner.returnable_date),
            available: scanner.available,
            status: scanner.status,
            assigned_to: scanner.assigned_to,
            location: scanner.location,
            team: scanner.team,

        });
        setShowScannerAddOverlay(true);
        setIsEditing(true);
        setSelectedScanner(scanner);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedScanners = React.useMemo(() => {
        let sortableScanners = [...scanner];
        if (sortConfig !== null) {
            sortableScanners.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableScanners;
    }, [scanner, sortConfig]);

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    const formatDate = (dateString) => {
        const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    return (
        <div className='scanner-page' >
            <header className="inventory-header">
                <h1>Scanner</h1>
            </header>
            <Inventory_header />
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>
            </div>

            <div className="searchBar">
                <button className="add-scanner-btn" onClick={() => {
                    clearNewScanner();
                    setSelectedScanner(null);
                    setIsEditing(false);
                    setShowScannerAddOverlay(true);
                }}>+ Add New Scanner</button>
                <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
            </div>

            <div className="scanner-table-container">
                <table className="scanner-table ">

                    <thead>
                        <tr>

                            <th>#</th> {/* Columna para contar las filas */}
                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model Type
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>


                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('scanner_type')}>
                                Scanner Type
                                {sortConfig?.key === 'scanner_type' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('Connector_cable')}>
                                Connector Cable
                                {sortConfig?.key === 'Connector_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('wireless')}>
                                Wireless
                                {sortConfig?.key === 'wireless' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>


                        </tr>
                    </thead>
                    <tbody>
                        {filteredScanners.map((scanner, index) => (
                            <tr key={scanner.id} onClick={() => handleEditScanner(scanner)}>
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="scanner-tagid">{capitalize(scanner.tagid)}</td>
                                <td className="scanner-brand">{capitalize(scanner.brand)}</td>
                                <td className="scanner-model">{capitalize(scanner.model)}</td>
                                <td className="scanner-model_number">{capitalize(scanner.model_number)}</td>
                                <td className="scanner-serial_Number">{scanner.serial_Number}</td>
                                <td className="scanner-price">${formatPrice(scanner.price)}</td>
                                <td className="scanner-store">{scanner.store}</td>
                                <td className="scanner-purchase_date">{scanner.purchase_date ? formatDate(scanner.purchase_date) : 'No Date'}</td>
                                <td className="scanner-charger_cable">{scanner.charger_cable ? 'Yes' : 'No'}</td>
                                <td className="scanner-scanner_type"> {scanner.scanner_type === 'desk' ? 'Desk' : 'Portable'}</td>
                                <td className="scanner-connector_cable">
                                    {scanner.Connector_cable === 'HDMI' ? 'HDMI Cable' : scanner.Connector_cable === 'C-C' ? 'C-C'
                                        : scanner.Connector_cable === 'C-USB'
                                            ? 'USB Cable'
                                            : 'Other'}</td>
                                <td className="scanner-wireless">{scanner.wireless ? 'Yes' : 'No'} </td>
                                <td className="scanner-assignment_date">{scanner.assignment_date ? formatDate(scanner.assignment_date) : 'No Date'}</td>
                                <td className="scanner-warranty_date">{scanner.warranty_date ? formatDate(scanner.warranty_date) : 'No Date'}</td>
                                <td className="scanner-returnable_date">{scanner.returnable_date ? formatDate(scanner.returnable_date) : 'No Date'}</td>
                                <td className="scanner-available">{scanner.available ? 'Yes' : 'No'} </td>
                                <td className="scanner-status">
                                    {scanner.status === 'broken' ? 'Broken' :
                                        scanner.status === 'use' ? 'Use' :
                                            scanner.status === 'transfer' ? 'Transfer' :
                                                scanner.status === 'lost' ? 'Lost' :
                                                    scanner.status === 'inv' ? 'Inventario' :
                                                        'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="scanner-assigned_to">{scanner.assigned_to}</td>
                                <td className="scanner-location">{scanner.location}</td>
                                <td className="scanner-team">{scanner.team}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}

            {loading && <p>Loading...</p>}

            {showScannerAddOverlay && (
                <div className="scanner_add_overlay" onClick={(e) => {
                    // Close overlay only if clicked outside the modal content
                    if (e.target.classList.contains("scanner_add_overlay")) {
                        setShowScannerAddOverlay(false);
                        clearNewScanner();
                        setSelectedScanner(null);
                        setIsEditing(false);
                    }
                }}>
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Scanner' : 'Add New Scanner'}</h2>
                            <button onClick={() => {
                                setShowScannerAddOverlay(false);
                                clearNewScanner();
                                setSelectedScanner(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedScanner.tagid : newScanner.tagid} onChange={handleInputChange} required />
                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedScanner.brand : newScanner.brand} onChange={handleInputChange} />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedScanner.model : newScanner.model} onChange={handleInputChange} required />
                        <label>Model #:</label><input name="model_number" placeholder="#" value={isEditing ? selectedScanner.model_number : newScanner.model_number} onChange={handleInputChange} />


                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedScanner.serial_Number : newScanner.serial_Number} onChange={handleInputChange} required />


                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedScanner.price : newScanner.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedScanner.store : newScanner.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedScanner.purchase_date : newScanner.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label>Charger Cable:</label>

                        <select name="charger_cable" placeholder="charger_cable" value={isEditing ? selectedScanner.charger_cable : newScanner.charger_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Scanner Type:</label>
                        <select name="scanner_type" placeholder="scanner_type" value={isEditing ? selectedScanner.scanner_type : newScanner.scanner_type} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Scanner Type</option>
                            <option value="desk">Desk</option>
                            <option value="portable">Portable</option>
                        </select>

                        <label>Connector Cable:</label>
                        <select name="Connector_cable" placeholder="connector_cable" value={isEditing ? selectedScanner.Connector_cable : newScanner.Connector_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Connector Cable Type</option>
                            <option value="HDMI">HDMI</option>
                            <option value="C-C">C-C</option>
                            <option value="C-USB">C-USB</option>
                            <option value="Other">Other</option>
                        </select>

                        <label>Wireless:</label>

                        <select name="wireless" placeholder="wireless" value={isEditing ? selectedScanner.wireless : newScanner.wireless} onChange={handleInputChange}>
                            <option value="" disabled hidden className="placeholder-option">Select Wireless</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedScanner.assignment_date : newScanner.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedScanner.warranty_date : newScanner.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedScanner.returnable_date : newScanner.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedScanner.available : newScanner.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedScanner.status : newScanner.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>
                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedScanner.assigned_to : newScanner.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/* Opción N/A */}
                        </select>
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedScanner.location : newScanner.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedScanner.team : newScanner.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-scanner" onClick={isEditing ? handleUpdateScanner : handleAddScanner}>
                                {isEditing ? 'Update Scanner' : 'Add Scanner'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-scanner"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this scanner?')) {
                                            handleDeleteScanner();
                                        }
                                    }}
                                >
                                    Delete scanner
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>

    );
};

export default Scanner;


import React, { useState, useEffect } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listClientFix2s, listUsersByTeamOne, clientsByNavigator } from '../../graphql/queries';
import { CREATE_CLIENTFIX2, UPDATE_CLIENTFIX2, DELETE_CLIENTFIX2 } from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import LoadingSpiner from '../../components/layout/LoadingSpinner';
import ClientForm from '../../components/ClientForm'; // Adjust the import path
import { capitalize } from '../../components/common/capitalize';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import './Clients.css';

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const navigate = useNavigate(); // Initialize the navigate hook
  const [selectedClient, setSelectedClient] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [showClientAddOverlay, setShowClientAddOverlay] = useState(false);
  const [fullName, setFullName] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false); // Tracks downloading state
  const ALLOWED_USER_GROUPS = ['Admin', 'Manager']; // Add all allowed groups here
  const userHasAccess = () => userGroups.some(group => ALLOWED_USER_GROUPS.includes(group));

  const [newClient, setNewClient] = useState({
    id: '',
    name: '',
    last_name: '',
    email: '',
    address: '',
    city: '',
    county: '',
    zip_code: '',
    prefered_lang: '',
    insurance_picked: '',
    last_contacted_date: '',
    navigator: '',
    payment_per_month: '',
    phone: '',
    plan: ''
  });
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [phoneError, setPhoneError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne);
  const [isLoading, setIsLoading] = useState(false);
  const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
    id: user.id,
    name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
  })) || [];
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [fetchAllClients, { data: allClientsData }] = useLazyQuery(listClientFix2s, {
    variables: { limit: 200 }, // Adjust as needed for pagination
    fetchPolicy: "network-only",
  });

  const handleRowClick = (client) => {
    setSelectedRow(client.id);
    handleEditClient(client); // Llama a tu función de edición
  };

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);

      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0];

      setUserDetails({
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        userId: signInDetails.loginId
      });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  // Define handleEditClient function
  const handleEditClient = (client) => {
    setSelectedClient(client);
    setIsEditing(true);
    setShowClientAddOverlay(true);
  };

  useEffect(() => {
    if (userDetails) {
      let fullName = `${userDetails.firstName} ${userDetails.lastName}`;
      console.log(fullName);
      if (fullName === "Darwish Ahmadi") {
        fullName = "Mohammad darwish Ahmadi";
      }
      setFullName(fullName);
    }
  }, [userDetails]);
  

  const { loading, data, fetchMore } = useQuery(
    userHasAccess() ? listClientFix2s : clientsByNavigator,
    {
      variables: userHasAccess()
        ? { limit: 1000 } // Fetch all clients for Admin users
        : { navigator: fullName || "Unknown Navigator!", limit: 1000 }, // Personal clients for other users
      skip: !userDetails && !userHasAccess(), // Skip query until userDetails are fetched
    }
  );

  useEffect(() => {
    if (data) {
      const fetchedClients = userHasAccess() ? data.listClientFix2s.items : data.clientsByNavigator.items;
      // Print the dates immediately after fetching the data

      setClients(fetchedClients);
    }
  }, [data, userGroups]);

  const [addClient] = useMutation(CREATE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });

  const [updateClient] = useMutation(UPDATE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });

  const [deleteClient] = useMutation(DELETE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });
  useEffect(() => {
    const fetchAllClients = async () => {
      let allClients = [];
      let currentNextToken = null;

      do {
        const response = await fetchMore({
          variables: userGroups.includes('Admin')
            ? { limit: 1000, nextToken: currentNextToken }
            : { navigator: fullName, limit: 1000, nextToken: currentNextToken },
        });

        const fetchedData = userGroups.includes('Admin') ? response?.data?.listClientFix2s : response?.data?.clientsByNavigator;

        if (fetchedData && fetchedData.items) {
          allClients = [...allClients, ...fetchedData.items];
          currentNextToken = fetchedData.nextToken;
        } else {
          console.warn("Unexpected response structure from fetchMore:", response);
          break;
        }
      } while (currentNextToken);

      setClients(allClients);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllClients();
    }
  }, [data, fetchMore, userGroups]);


  const clearNewClient = () => {
    setNewClient({
      id: '',
      name: '',
      last_name: '',
      email: '',
      address: '',
      city: '',
      county: '',
      zip_code: '',
      prefered_lang: '',
      insurance_picked: '',
      last_contacted_date: '',
      navigator: '',
      payment_per_month: '',
      phone: '',
      plan: ''
    });
  };

  // Define loadMore function
  const loadMore = () => {
    fetchMore({
      variables: userGroups.includes('Admin')
        ? { limit: 1000, nextToken }
        : { navigator: fullName, limit: 1000, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;

        const updatedItems = userGroups.includes('Admin')
          ? [...prevResult.listClientFix2s.items, ...fetchMoreResult.listClientFix2s.items]
          : [...prevResult.clientsByNavigator.items, ...fetchMoreResult.clientsByNavigator.items];

        const newNextToken = userGroups.includes('Admin')
          ? fetchMoreResult.listClientFix2s.nextToken
          : fetchMoreResult.clientsByNavigator.nextToken;

        return userGroups.includes('Admin')
          ? {
            listClientFix2s: {
              ...prevResult.listClientFix2s,
              items: updatedItems,
              nextToken: newNextToken,
            },
          }
          : {
            clientsByNavigator: {
              ...prevResult.clientsByNavigator,
              items: updatedItems,
              nextToken: newNextToken,
            },
          };
      },
    });
  };

  const handleNavigateToOptOutClients = () => {
    navigate('/opt-out-clients'); // Navigate to the /opt-out-clients route
  };

  const handleDeleteClient = () => {
    setIsLoading(true); // Set loading state to true

    deleteClient({
      variables: {
        input: {
          id: selectedClient.id
        }
      },
      update: (cache) => {
        const existingClients = cache.readQuery({ query: listClientFix2s, variables: { limit: 1000 } });
        const updatedClients = existingClients.listClientFix2s.items.filter(
          client => client.id !== selectedClient.id
        );

        cache.writeQuery({
          query: listClientFix2s,
          variables: { limit: 1000 },
          data: {
            listClientFix2s: {
              ...existingClients.listClientFix2s,
              items: updatedClients
            }
          }
        });
      }
    }).then(() => {
      setSelectedClient(null); // Clear selected client
      setShowClientAddOverlay(false); // Hide overlay
    }).catch(error => {
      console.error("Error deleting client:", error);
    }).finally(() => {
      setIsLoading(false); // Reset loading state
    });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedClients = React.useMemo(() => {
    let sortableClients = [...clients];
    if (sortConfig !== null) {
      sortableClients.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableClients;
  }, [clients, sortConfig]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredClients = sortedClients.filter(client => {
    const fieldValue = client[searchField] !== null && client[searchField] !== undefined
      ? client[searchField].toString().toLowerCase()
      : '';
  
    const searchValue = searchQuery ? searchQuery.toString().toLowerCase() : '';
  
    return fieldValue.includes(searchValue);
  });

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };




  const downloadExcel = async () => {
    if (!userHasAccess()) {
      console.error("Access denied: You do not have permission to download data.");
      return;
    }

    setIsDownloading(true); // Start loading spinner

    try {
      let allClients = [];
      let currentNextToken = null;

      // Loop to fetch all clients until no next token is returned
      do {
        const response = await fetchAllClients({
          variables: { limit: 1000, nextToken: currentNextToken },
          fetchPolicy: "network-only",
        });

        const fetchedClients = response.data.listClientFix2s.items;
        allClients = [...allClients, ...fetchedClients];
        currentNextToken = response.data.listClientFix2s.nextToken;
      } while (currentNextToken);

      if (allClients.length === 0) {
        alert("No clients available to download.");
        setIsDownloading(false);
        return;
      }

      // Format the fetched clients for the Excel file
      const formattedClients = allClients.map(client => ({
        ID: client.id,
        Name: capitalize(client.name),
        "Last Name": capitalize(client.last_name),
        Email: client.email.toLowerCase(),
        Address: client.address,
        City: client.city,
        County: client.county,
        zip_code: client.zip_code,
        "Preferred Language": Array.isArray(client.prefered_lang) ? client.prefered_lang.join(', ') : client.prefered_lang,
        "Insurance Picked": client.insurance_picked,
        Plan: client.plan,
        "Last Contacted Date": client.last_contacted_date,
        Navigator: client.navigator,
        "Payment Per Month": client.payment_per_month,
        Phone: formatPhoneNumber(client.phone)
      }));

      // Create the Excel file
      const worksheet = XLSX.utils.json_to_sheet(formattedClients);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");

      // Download the file
      XLSX.writeFile(workbook, "clients.xlsx");
    } catch (error) {
      console.error("Error fetching all clients:", error);
    } finally {
      setIsDownloading(false); // Stop loading spinner after download completes
    }
  };

  return (
    <div className="clients">
      <header className="clients-header">
        <h1>Clients</h1>
      </header>
      <div className="clients-search">
        <input
          type="text"
          placeholder="Client Name, Email, Address"
          value={searchQuery}
          onChange={handleSearch}
        />
        <select onChange={(e) => setSearchField(e.target.value)}>
          <option value="name">Name</option>
          <option value="last_name">Last Name</option>
          <option value="email">Email</option>
          <option value="address">Address</option>
          <option value="insurance_picked">Insurance Picked</option>
          <option value="last_contacted_date">Last Contacted Date</option>
          <option value="navigator">Navigator</option>
          <option value="payment_per_month">Payment Per Month</option>
          <option value="phone">Phone</option>
          <option value="plan">Plan</option>
        </select>
      </div>
      <button
        className="add-user-btn"
        onClick={() => {
          clearNewClient();
          setSelectedClient(null);
          setIsEditing(false);
          setShowClientAddOverlay(true);
        }}
      >
        + Add User
      </button>
      {userGroups?.includes('Admin') && (
        <button className="download-excel-btn" onClick={downloadExcel} disabled={isDownloading}>
          {isDownloading ? <LoadingSpiner /> : "Download Clients Excel"}
        </button>
        
      )}
       <button
          className="navigate-opt-out-btn" // Add a custom class for styling if needed
          onClick={handleNavigateToOptOutClients}
        >
          View Opt-Out Clients
        </button>
      <div className="table-container">
        <table className='clients-table'>
          <thead>
            <tr>
              <th className="client-id" onClick={() => requestSort('id')}>
                Client ID
                {sortConfig?.key === 'id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-name" onClick={() => requestSort('name')}>
                Name
                {sortConfig?.key === 'name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : ' sort-ascending'} />
                )}
              </th>
              <th className="client-last-name" onClick={() => requestSort('last_name')}>
                Last Name
                {sortConfig?.key === 'last_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-phone" onClick={() => requestSort('phone')}>
                Phone
                {sortConfig?.key === 'phone' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-email" onClick={() => requestSort('email')}>
                Email
                {sortConfig?.key === 'email' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-preferred-language" onClick={() => requestSort('prefered_lang')}>
                Preferred Language
                {sortConfig?.key === 'prefered_lang' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-address" onClick={() => requestSort('address')}>
                Address
                {sortConfig?.key === 'address' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-city" onClick={() => requestSort('city')}>
                City
                {sortConfig?.key === 'city' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-county" onClick={() => requestSort('county')}>
                County
                {sortConfig?.key === 'county' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-insurance" onClick={() => requestSort('insurance_picked')}>
                Insurance Picked
                {sortConfig?.key === 'insurance_picked' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-plan" onClick={() => requestSort('plan')}>
                Plan
                {sortConfig?.key === 'plan' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-last-contacted" onClick={() => requestSort('last_contacted_date')}>
                Last Contacted Date
                {sortConfig?.key === 'last_contacted_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-navigator" onClick={() => requestSort('navigator')}>
                Navigator
                {sortConfig?.key === 'navigator' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-payment" onClick={() => requestSort('payment_per_month')}>
                Payment Per Month
                {sortConfig?.key === 'payment_per_month' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

            </tr>
          </thead>
          <tbody>
            {filteredClients.map(client => (
              <tr
                key={client.id}
                className={selectedRow === client.id ? 'selected-row' : ''}
                onClick={() => handleRowClick(client)}
              >
                <td className="client-id">{client.id}</td>
                <td className="client-name">{capitalize(client.name)}</td>
                <td className="client-last-name">{capitalize(client.last_name)}</td><td className="client-phone">{formatPhoneNumber(client.phone)}</td>
                <td className="client-email">{client.email ? client.email.toLowerCase() : 'N/A'}</td>
                <td className="client-preferred-language">
                  {Array.isArray(client.prefered_lang) ? client.prefered_lang.join(', ') : client.prefered_lang}
                </td><td className="client-address">{client.address}</td>
                <td className="client-city">{client.city}</td><td className="client-county">{client.county}</td>
                <td className="client-insurance">{client.insurance_picked}</td><td className="client-plan">{client.plan}</td>
                <td className="client-last-contacted">{moment(client.last_contacted_date).format('MM-DD-YYYY')}</td>
                <td className="client-navigator">{client.navigator}</td>
                <td className="client-payment">
                  {"$" + (client.payment_per_month !== null ? Number(client.payment_per_month).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : "0.0")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="item-count">
        <p>Total Clients: {filteredClients.length}</p>
      </div>
      {nextToken && !loading && (
        <button onClick={loadMore}>Load More</button>
      )}
      {showClientAddOverlay && (
  <div
    className="client_add_overlay"
    onClick={(e) => {
      // Close the overlay only if the click is outside the modal content
      if (e.target.classList.contains("client_add_overlay")) {
        setShowClientAddOverlay(false);
        setSelectedClient(null);
        setIsEditing(false);
      }
    }}
  >
    <div className="overlay-content-clients" onClick={(e) => e.stopPropagation()}>
      <ClientForm
        isEditing={isEditing}
        selectedClient={selectedClient}
        onClientAdded={() => {
          // Callback to handle any post-submission logic
          setShowClientAddOverlay(false);
          setSelectedClient(null);
          setIsEditing(false);
          // Refetch or update client list as needed
        }}
        onClose={() => {
          // Close the form overlay
          setShowClientAddOverlay(false);
          setSelectedClient(null);
          setIsEditing(false);
        }}
        userGroups={userGroups}
        handleDeleteClient={handleDeleteClient}
      />
    </div>
  </div>
)}

    </div>
  );
};

export default ClientsTable;
